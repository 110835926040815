const wereMonsters = [
  {
    name: "",
    path: "were-monsters",
    root: "were-monsters",
    items: [
      {
        name: "Жабалака",
        src: "zhabalaka",
        description: `<i>1542 год. Двое падарожнікаў павярнулі з дарогі на Браслаў у бок лесу.</i>
<br/><br/>***
<br/><br/>–  Дзед Кастусь, ты заўсёды гэтымі балотнымі сцежкамі з Гунілаў у Браслаў ходзіш?
<br/>– Ну… так, так карацей выходзіць. Але трэба ведаць тут крыху, дзе, што, як ісці…
<br/>– Вах! А гэта яшчэ што за цуды?!!! – раптам абарваў старога малады Янка.
<br/><br/>Праваруч ад падарожнікаў убок ад сцежкі проста з балота тырчалі верхавіны нейкіх будынкаў. Адно было прыкметна больш за іншыя. Было відаць яго разбітыя вокны другога паверха, з якіх туды сюды скакалі і квакалі жабы. Першы ж паверх быў затоплены, вокны і ўваходныя дзверы пайшлі цалкам пад ваду. Сцены будынка моцна счарнелі, паўсюль быў мох і іншая расліннасць. У суседніх будынкаў з вады тырчалі толькі дзіравыя дахі.
<br/><br/>– Гэта ж хто дадумаўся палац сабе на балоце пабудаваць?
<br/>– Калісьці гэта была сядзіба Дамініка Жабы – адказаў дзед Кастусь.
<br/>– Мянушка што трэба! Мусіць, таму сабе і такое месца абраў? – пажартаваў Янка.
<br/>– А раней тут не было ні балота, ні лесу. Вось так! Прыгожы дагледжаны парк і ўсё сухенька…
<br/>– І даўно?
<br/>– З гадоў дзесяць таму…
<br/>– Да ну! Як такое магчыма?
<br/>– У прыроды свае дзівацтвы. Але ёсць і мясцовая легенда, якая гэта ўсё тлумачыць.
<br/><br/>Кажуць, Дамінік гэты быў той яшчэ кабель, ніводнай маладой спадніцы не прапускаў. А тут недалёка, у Старым Пагосце, прыгажуня мясцовая жыла. Глаша клікалі. Жаба гэты на яе ўсе сліну пускаў, і так, і гэтак, а яна ні ў якую. Нарэшце звар'яцеў ён на ёй, усё гатовы быў аддаць, абы яна з ім была. Кляўся ёй у каханні, вернасці, вяселле абяцаў… а потым, калі атрымаў што хацеў, то падмануў. Ну дзе ён, а дзе вясковая дзеўка.
Праз нейкі час Дамінік гэты ажаніцца надумаў. Маці яго яму партыю, казалі, добрую знайшла. Толькі вось па прыездзе той яго нявесты ў сядзібу і Глаша заявілася. Аказалася, ведзьмай яна была. З-за дурніцы і гора заклён яна страшны наклала, чарцям там жа парад усімі жыццё сваё аддала. Вось сядзіба пад зямлю ў балота і правалілася.
А Дамінік, прыгажун, у пачвару жудаснцю ператварыўся і ўсіх сваіх (і сваякоў, і гасцей) на кавалкі разарваў. Пры чым, кажуць, ён памерці не можа, несмяротны, і вось у гэтым абліччы да гэтага часу тут жыве. Не можа ён гэтую сядзібу пакінуць…
<br/><br/>– Дзед. Ну, жудасць якая…
<br/>– Ну, жудасць не жудасць, а пару хлопцаў мясцовых у гэтую сядзібу залезці хацелі. Байкі ходзяць, што золата там. Але, прапалі. Бабкі палохаюць, што Жаба іх разарваў, а я думаю, што патанулі. Туды падыходу зусім няма. Пойдзем Ян, да вечара трэба да Браслава дайсці.
<br/><br/>Дзед Кастусь паціху пайшоў наперад далей па сцежцы. Янка яшчэ нейкі час глядзеў на сядзібу. Узіраючыся ля даху аднаго з флігеляў, ён нібыта ўбачыў сілуэт рыцара. Той у іржавых даспехах стаяў нерухома. Замест чалавечай галавы ў яго была галава вялізнай жабы. І чорныя вочы, якія глядзелі на Яна. 
<br/>Хлопец паспяшаўся наперад…`,
        source: "",
      },
      {
        name: "Лісалак",
        src: "lisalak",
        description: `<i>1513 год. Маёнтак роду Ваньковічаў недалёка ад Вілейкі. Начныя залы сядзібы скрозь адчыненыя вокны асвятляе месяц. У адным з такіх залаў можна ледзь заўважыць дзве чалавечыя постаці, якія стаяць насупраць велізарнага габелена з гербам “Ліс”.</i>
<br/><br/>— Яго тут няма, гэта ўсё байкі. У гэтым маёнтку ўвогуле ніводнай душы!
<br/>— Старая не магла памыліцца, яна ніколі не памыляецца.
<br/>— Я не веру ў …. Стой! Ціха! Ты чуеш?
<br/><br/>З самага цёмнага кута пакоя пачуліся дзіўныя гукі: рык, шыпенне і скрыгат жалеза. Загарэліся два чырвоныя агеньчыкі, нібы нечыя злавесныя вочы.
<br/><br/>— Юзаф? Гэта ён! Да мяне дайшоў пах сабакі!
<br/><br/>Чорныя постаці агалілі свае нажы. У тое ж імгненне з цёмнага кута ў іх бок пранёсся велізарны цень і толькі на імгненне ў промні месяца бліснулі іклы і жалезныя даспехі.
<br/><br/>***
<br/>Двума днямі пазней. Старая хата на ўскрайку лесу каля возера Нарач.
<br/><br/>— Дастань яго Юзаф, яна чакае, аддай ёй.
<br/><br/>Пачварная гарбатая старая выхапіла з рук злодзея вялізнае акрывавленае сэрца.
<br/><br/>— Сэрца звычайнага лісу і кашэчая печань гэта асноўныя кампаненты ўзвара. — старая падышла да кацялка і кінула сэрца ў кіпячае зелле. Яна зачарпнула з чана зелле і паднесла да свайго рота.
<br/>— Узвар нязначна амалоджвае ведзьму, але гэта з сэрцам звычайнага ліса…
<br/><br/>Старая сербанула зелле.
<br/><br/>— З сэрцам Лісалака дзеянне ўзвара ўзмацняецца ў сотню разоў, яно дапаможа мне вярнуцца ў Мядзел.
<br/><br/>Юзаф выпусціў з рук сумку. Перад ім стаяла неапісальнай прыгажосці маладая дзяўчына.`,
        source: "",
      },
      {
        name: "Дзікалак",
        src: "dzikalak",
        description: `<i>1555 год. Карчма каля замка ў Браславе.</i>
<br/><br/>У карчму заходзіць літвінскі ваяр і рушыць да гаспадара. Спыняецца і амаль шэптам кажа:
<br/><br/>— Клятыя крыжакі! Налей, Стась, мне кубачак сбіценя. Што гэта за “чорны дзік” тут?
<br/>— Інфлянты. Магнус…Магнус Дэнхоф едзе да Чорнага з нейкім пісьмом ад Готхарда — адказаў ваяру гаспадар карчмы.
<br/>— Ну, так, у нас цяпер сяброўства. Здаровыя гэтыя інфлянты…А чаму гэтай дарогай праз Брасл….
Не паспеў літвінскі ваяр скончыць сваё пытанне, як у карчму ўварвалася маладая дзяўчына. Маланкай яна пранеслася праз карчму і спынілася каля стала, дзе сядзелі іншаземныя рыцары.
<br/><br/>Дзяўчына была вельмі прыгожая, статная, але адное яе вока было цалкам белае, на твары быў бачны шрам. Яна была не з замкавых, верагодна з нейкай суседняй вёскі.
<br/><br/>— Памятаешь мяне? Ты! — гучна кінула дзеўчына ў бок самага вялікага з рыцараў.
<br/><br/>Магнус кінуў на яе касы позірк і толькі ўсміхнуўся. Дзеўчына дастала з торбы кавалак чарназёму і кінула ў твар рыцара. Інфлянты адразу падскочылі, нехта нават агаліў меч. У карчме стаяла цішыня. Дзяўчына пайшла ў бок дзвярэй, усе позіркі былі на ёй, перад тым як выйсці яна сказала:
<br/><br/>— Ты не памрэш! Не! Ты будзеш жыць і пакутваць, тутэйшыя лясы — помста больш страшная, чым смерць — яна грукнула дзвярыма і выйшла.

<br/><br/>***
<br/>Праз месяц, недзе ў лесе каля мястэчка Опса. Трое паляўнічых стаяць сярод палеглых рыцараў. Усе целы страшна разарваныя, дзе рука ляжыць, дзе нага. Паўсюль рэшткі пашкоджаных даспехаў.
<br/><br/>— Напэўна, гэта тыя самыя інфлянты, якія не даехалі да Радзівіла?
<br/>— Ну, так. Выглядае, што яны.
<br/><br/>Гучны візг і рык перашкодзіў паляўнічай гаворцы. Усе, як адзін, кінулі свае позіркі на невялічкае ўзвышша. На ім стаяў на задніх лапах, быццам бы чалавек, вялізны вяпрук закаваны ў латы. Уся яго морда была вымазана крывёю а вочы ззялі золатам. Гэта быў Дзікалак. Магнус.`,
        source: "",
      },
      {
        name: "Вожалак",
        src: "vozhalak",
        description: `<i>1536 год. Радуньскі замак. Раніца. Вершнік галопам нясецца праз мост у замкавую ўязную браму.</i>
<br/><br/>— Вось! Вось яна, трава гэтая! Зелле… Яна сказала, каб піў яго гарачым, пакуль не астыла! — запыхаўшыся кажа вершнік, саскокваючы з каня.
<br/><br/>Да вершніка павольна наблізіўся высокі і хударлявы мужчына, з ног да галавы апрануты ў чорнае адзенне, з такой жа чорнай доўгай барадой і белым, як снег, тварам.
<br/><br/>— Супакойся, Хведар! Хто яна? Пра каго ты кажаш? — запытаўся чалавек у чорным.
<br/>— Дык старая, тая самая старая, што каля Кемейш жыве. Княжна мяне да яе за зеллем для спадара пасылала…
<br/>— Добра, добра. Давай, я аднясу яго ў Кутнюю Вежу. Паклікайце спадарыню! Здаецца, сёння ў вечары будзе свята! — абарваў яго чалавек у чорным і выхапіў торбу, у якой быў збанок з зеллем.
<br/><br/>***
<br/>Цёмны падземны драўляны пакой пад Кутняй Вежай. Каля сцяны, нібы вялізны чалавек, нейкая істота закаваная ў ланцугі ды калодкі стаіць на каленях.
<br/><br/>Скура ў гэтай істоты шэрага колеру, рэбры неяк жудасна тырчаць з тулава і, здаецца, іх нашмат больш, чым у звычайнага чалавека. Замест валос — іклы, быццам бы сотні кінжалаў, а вочы чорныя, як ноч, што без зорак і месяца.
<br/><br/>У пакой уваходзяць некалькі ваяроў і чалавек у чорным адзенні, які трымае ў руках бронзавы кубак.
<br/><br/>Шэрая пачвара пачынае біцца і рваць ланцугі, жудасна шыпець быццам бы змяя. Але яе настрой раптам змяняецца, калі ў пакоі з’яўляецца княжна замка са сваімі служкамі — Аляксандра Сулькевіч.
<br/>Княжна бярэ бронзавы кубак і падыходзіць да шэрай пачвары. Шэптам яна кажа:
<br/><br/>— Каханы, гэта я… Ты пазнаешь мяне? Анжэй? Трэба гэта выпіць… Яно дапаможа табе… Дапаможа нам…
<br/><br/>***
<br/>Ноч. Гаспадарскі дом у замку. Пакой чорнага чалавека. На стале стаіць збан з зеллем, якое прывез зранку вершнік. Збан поўны да краёў. Побач стаіць бронзавы кубак і іншы збан, які напалову пусты. Чорны чалавек сядзіць на стуле і ў руках трымае нейкі пергамент. Можна разабраць некалькі словаў: “Расол”, “разрыў-трава”.
<br/><br/>— Сёння… Сёння… — паўтарае чорны чалавек і працягвае — Сёння будзе свята!
<br/><br/>За вокнамі начную цішыню пачынаюць абрываць крыкі. Чутны галасы:
<br/><br/>— Ааа! Ратуйцеся! Вожалак на волі!`,
        source: "",
      },
      {
        name: "Трусалак",
        src: "trusalak",
        description: `<i>1409 год. Лясы каля Ўслоніма.</i>
<br/>– (Нямец.) Я бачу яе!
<br/>– (Нямец.) Так, падобна гэта яна. Стары казаў, што як мінем гэты лес, то адразу ўбачым яе шпіль.
<br/><br/>– Нам давядзецца пакінуць коней.
<br/>– Гэта навошта яшчэ?
<br/>– Далей мы пойдзем цераз балота, трэба пехам.
<br/>– Няўжо няма іншай дарогі?
<br/>– Прайсці да вежы можна толькі праз гэтыя багны.
<br/><br/>– (Нямец.) Можа гэты стары спецыяльна нас сюды вядзе, каб мы згінулі тут у гэтай багне? З чаго ўвогуле ты яму верыш, Ульрых?
<br/>– (Нямец.) Ён не першы ад каго я чуў пра гэты скарб. Апроч патрачанага часу, нам нічога не варта праверыць гэтае месца.
<br/>– (Нямец.) А што ён казаў пра нейкіх каўматых пачвар?
<br/>– (Нямец.) Гэта старэчыя трызненні, страшылкі для юнакоў. Я думаю гэта прыдумалі мясцовыя, каб адбіць жаданне ў падарожнікаў совацца ў гэты бок. Кажуць, гэты Бэлтовіч меў стасункі з самымі пачварнымі мясцовымі вядзьмаркамі, мо адтуль гэтыя байкі…
<br/>– Сунешся тут, тут і прайсці-та як?
<br/><br/>Тэўтонцы прыняліся здымаць з сябе латы. Стары Францішак прывязаў коней да дрэва і ўвесь час узіраўся ў гушчар лесу…
<br/><br/>***
<br/><br/>Каля ўвахода ў вежу.
<br/><br/>– Гэта дзіўна…
<br/>– Што не так, Зігмунт?
<br/>– Ідэальна роўныя, гладкія. Нібы кожны камень падбіраўся спецыяльна, кожны на сваім месцы. Але яны ніяк не апрацованыя!
<br/>– І сапраўды, мабыць будаўнікам прыйшлося патраціць не мала часу, каб такое пабудаваць.
<br/>– Гэта пабудавалі не людзі… - абарваў крыжакоў Францішак.
<br/>– А хто? Зноў стары ты са сваімі байкамі.
<br/><br/>Крыжакі агалілі мячы і ўвайшлі ў цёмру вежы. Ступені лесвіцы вялі глыбока ўніз.
<br/><br/>***
<br/><br/>Нарэшце свет - гэта выхад. Ульрых, кульгаючы, ледзь выпаўз. Па яго целе струіліся ручайкі крыві. З спіны тырчалі тры стралы. Зігмунта не было. Крыжак дапоўз да края пляцоўкі і зірнуў уніз. Неверагодна! Увесь час яны паглыбляліся ўніз вежы, нават пасля нападу жоўтавокай пачвары. Як так атрымалася, што зараз ён быў на самым версе вежы?
<br/><br/>Крокі. Лязг. На вежавай пляцоўцы з'явілася постаць невядомай істоты пакрытай шэрай поўсцю з жудаснымі жоўтымі вачыма. Яна павольна падыйшла да Ульрыха і наставіла на яго арбалет. Ульрых ляжаў на жываце і глядзеў уніз праз край вежы. Тамака стаяў Францішак. Вочы яго гарэлі жоўтым. Ён бачыў і пачвару, і крыжака. Вжых. Болт прабіў галаву Ульрыху.`,
        source: "",
      },
      {
        name: "Крумкалак",
        src: "krumkalak",
        description: `<i>1564 год. Рака Ула.</i>
<br/><br/>Мікалай Ястржэмбскі ў сваім шатры схіліўся над невялічкім столікам, на якім стаяла бутэлька з нейкім зеллем. Душа яго раздзіралася паміж паганскім і хрысціянскім пачаткам. Ён жа крумкач! Корвін! Спадчыннік вялікага літвінскага роду герба Слепаўрон. Крумкач сімвалізуе мудрасць, востры розум і даўгалецце. Чаму ён так баіцца гэтай бойкі?
<br/><br/>— Пій! — шапнуў яму на вуха чорт.
<br/><br/>Прачнуўшыся пасярод ночы шляхціц адчуў невыносны боль у галаве і страшны запал у грудзях, ён выбег з шатра. Хтосьці з салдат каля вогнішча выгукнуў: “Кадук яго! Гэта ж Крумкалак!”.
<br/><br/>Лязг мячоў, крыкі і грукат. Калі ўсё скончылася, Мікалай звярнуў позірк на свае акраваўленыя рукі, яны былі пакрытыя ці то чорнай поўсцю, ці то дробным пер’ем. На руках былі пачварныя вострыя кіпцюры. Дзе люстэрка? Шляхціц хацеў нешта выгукнуць, але замест гэтага пачулася: “Кааар!!”.
<br/><br/>Я знайду цябе Расол! Ты вернеш мне тое, што скраў! Крумкалак.`,
        source: "",
      },
      {
        name: "Куналак",
        src: "kunalak",
        description: `1529 год. Клецкі замак. Страшны праклён падае на юнага Радзівіла...`,
        source: "",
      },
      {
        name: "Барсукалак",
        src: "barsukalak",
        description: `<i>1543 год. Замак у Геранёнах. Малады прынц Жыгімонт Аўгуст III прыязджае ў маёнтак Гаштольдаў дапамагчы вырашыць маёмасныя справы ўдавы Барбары Радзівіл. Гэта афіцыйная мэта візыту. Насамрэч, маладая ўдава і прынц закаханыя, другі тыдзень яны праводзяць разам час у далечыні ад сталічных інтрыг.</i>
<br/><br/>***
<br/>У адным з замкавых пакояў вядуць гутарку двое са світы прынца: Якаў Няміра — сын каралеўскага каморніка, і Марцін — лоўчы прынца.
<br/><br/>— Як ты, Марцін, лічыш, гэта яна шчыра? Ці хітрасць, каб кароль па праву кадука не забраў маёнтак?
<br/>— Яны маладыя! Я ня думаю, што тут ёсьць нейкі разлік. Ды і сама Барбара заквітнела, гэта бачна.
<br/>— Яшчэ б, малады прынц! Ды яшчэ і ў росквіце сіл, а Станіс быў стары для яе і без году нябожчык…Хм…Такі магутны род і нікога не засталося…
<br/>— Ну, людзі тут рознае кажуць.
<br/>— Ты пра яго брата?
<br/>— Так. Людзі гавораць ён жывы.
<br/>— Я чуў, што ён нібыта звар’яцеў, а потым уцёк з замка. Растварыўся сярод сялянства! Альбрэхт яго шукаў і не знайшоў.
<br/>— Гэтая гісторыя ахутана містыкай. Канюшы мне расказаў увогуле нейкую дзіўную казку. Пры гэтым усе да адзінага паляўнічыя замка мне яе пацвердзілі. І нават на кухні гаварылі…
<br/><br/>У адну з вельмі снежных зім, малодшы сын Альбрэхта Юрый Гаштольд (родны брат Станіслава) адправіўся на паляванне. Дзічыны амаль не было, але ён напаў на след нейкіх істот.
<br/><br/>Спачатку паляўнічыя вырашылі, што гэта статак ланяў. Але, па меры пераследу, яны разгледзелі істот лепш. Ад лані ў тых былі толькі рогі. Істоты былі неверагодна хуткія і падобныя больш на людзей, пакрытых белай поўсцю.
<br/><br/>Сляды на снезе завялі Юрыя ў самы гушчар старажытнага лесу. Світа прасіла вярнуцца назад, але малады дваранін быў настойлівы і нават кінуў коней каб прайсці далей…
<br/><br/>У глыбіні лесу перад праследавальнікамі паўстаў вялізны валун. Яго было ніяк не абысці. Сляды вялі наўпрост праз яго. Ледзь Юры дакрануўся да яго і…
<br/><br/>Раптам пачуўся жаночы крык і Юрыя адкінула ўбок. З расколіны ў валуне вылецеў струмень шэрага дыму і, як змяя, запаўз прама ў рот двараніна. На вачах паляўнічых малады Юры пачаў ператварацца ў нейкага жахлівага звера. Ён накінуўся на паляўнічых і пачаў ірваць тых ікламі і кіпцюрамі.
<br/><br/>Хтосьці кінуўся бегчы прэч і змог выратавацца. Альбрэхт некалькі разоў пасылаў на пошукі салдат, але тыя не знайшлі ні Юрыя, ні целаў загінуўшых, ні вялізнага валуна…
<br/><br/>Альбрэхт шмат раіўся са знахарамі, нават да нейкай вядзьмаркі звяртаўся. Але пасля, прыпыніў пошукі і загадаў больш ніколі ў тым лесе не паляваць. Мясцовыя кажуць, што пярэварацень і дагэтуль ахоўвае логава тых дзіўных істот. Некаторыя паляўнічыя яго сустракалі. Іншыя гавораць, што бачылі Юрыя ў чалавечым абліччы ў вёсцы, але з дзікімі вачыма.
<br/><br/>— Гэта цікава… — задуменна выказаў Якаў і працягнуў,
<br/>— Здаецца, вось нам, Марцін, і месца для наступнага палявання…`,
        source: "",
      },
      {
        name: "Бабралак",
        src: "Babralaka",
        description: `1540 год. З Ашмянскага замка выязджае зграя вершнікаў на чале з Аляксандрам Хадкевічам. Коннікі адпраўляюцца на паўночны ўсход у невялікае селішча, дзе сяляне займаюцца бабровым промыслам.
<br/><br/>Сялян, якія разводзяць баброў і здабываюць мех і бабровы струмень, называюць баброўнікі.
<br/><br/>У мястэчку Ліпаўка бунт баброўнікаў. Яны не жадаюць плаціць нядаўна павялічаную павіннасць і патрабуюць перамоваў са старастай. Прыбыўшы на месца, Аляксандр пасля размоваў з мясцовымі зразумеў, што галоўны завадатар хваляванняў стары баброўнік Нікадзім. Кажуць, ён ужо даўно "крывы" і кожная Расамаха яго ведае ўздоўж усёй ракі Ашмяны.
<br/><br/>Аляксандр вырашае пытанне ціха. Уначы яго слугі схапілі Нікадзіма і забілі, а потым утапілі. Без галоўнага падбухторшчыка стараста хутка дамовіўся з астатнімі сялянамі.
<br/><br/>***
<br/>На беразе ракі Ашмяны. Аляксандр спешыўся памыцца ў рацэ, бо надвор'е было гарачае. Адкуль не вазьміся з'явіўся невялікі сівы бабёр і ўкусіў старасту за нагу. Аляксандр паспеў убачыць вочы жывёлы, перад тым, як тая адыйшла ў ваду. Яны былі чалавечыя і позірк кагосьці яму нагадаў.
<br/><br/>***
<br/>Праз два дні ў Ашмянскім замку знік стараста Аляксандр Хадкевіч. А на раніцу трэцяга дня варта закалола дзідамі ў яго пакоі жудасную істоту паўчалавека-паўбабра.`,
        source: "",
      },
    ],
  },
];

export default function loadWereMonsters() {
  return {
    data: {
      categories: wereMonsters,
      title: "ПЕРАВАРАТНІ",
      description: `Пярэваратні - ідэя, якая ўзнікла пасля прачытання працы Паўла Шпілеўскага "Даследаванне аб ваўкалаках на падставе беларускіх павер'яў".
       Акрамя ўсім вядомага чалавека-ваўка (Ваўкалака), Шпілеўскі таксама згадвае істот Жабалака і Кашкалачэнь - пярэваратняў, якія ператвараюцца ў чалавека-жабу і чалавека-ката адпаведна.
       <br/>Мне падалася цікавай ідэя стварыць шэраг ілюстрацый розных пярэваратняў з іншымі значнымі жывёламі з фауны і фальклору Беларусі.
       Пры гэтым, калі гаворка ідзе аб нячысціках і ніжэйшай міфалогіі, то гэта гаворка заўсёды менавіта аб вясковых жыхарах. У гэтых ілюстрацыях мне захацелася сканструяваць міфалагічныя абразы, якія закранулі б шляхту, дваранства і арыстакратыю сярэднявечных зямель Беларусі.
       <br/>Падкрэсліць гэтую асаблівасць я вырашыў выкарыстаннем радавых гербаў, даспех (спачатку з Радзівілаўскай калекцыі, пазней ужо ў свабодным стылі), а таксама невялікіх тэкставых эцюдаў з жахлівымі гісторыямі пра беларускіх пярэваратняў. 
      `,
    },
  };
}
