const forest = [
  {
    name: "Аднарог",
    src: "Adnarog",
    description: `Адзінарог або Аднарог - міфічная істота, якая выглядала як конь з адным рогам, які расце з ілба. Адзінарог, як жывёла, вядомы ўжо тысячы гадоў і з'яўляўся ў фальклоры мноства краін і народаў.<br/><br/>
У сярэднявеччы на беларускіх землях Адзінарог быў вядомы найперш дзякуючы гербу Боньча і розным яго варыяцыям, якім карысталіся прадстаўнікі дваранскіх родаў Польшчы, Беларусі, Літвы, Украіны і Расіі. Адзінарог мог быць і шчытатрымальнікам, і клейнодам, адлюстроўваўся таксама на кафлі ў палацах.<br/><br/>
Лічылася, што рог Адзінарога валодае гаючымі ўласцівасцямі, можа вылечыць ад яду, таму ў сярэднявечнай Беларусі ў многіх аптэках і лекарскіх хатах можна было купіць рог або парашок з яго (насамрэч, часцей гэта быў рог нарвала або бівень маржа).<br/><br/>
Паводле адной з легенд, апошні Адзінарог у Еўропе быў злоўлены і запалонены ў Беларусі. Князь Юрый Слуцкі з роду Алелькавічаў любіў таемна наведвацца да аднаго старога яўрэйскага знахара. Той па яго загаду спрабаваў знайсці спосаб, якім можна было б злавіць Адзінарога, якога княскія паляўнічыя мноства разоў бачылі пад Капылём. Адзінарог жывёла дзікая і вельмі хуткая. Прывабіць Адзінарога можна толькі спевам юнай нявінніцы. На гэтую ролю падышла дачка старога яўрэя, у якую пазней закахаўся і сам князь.<br/><br/>
Аднарог быў злоўлены, пазней яго рог трапіў ва маёмасць Радзівілаў, як і сам Слуцк, у яго нават быў свой інвентарны нумар.<br/><br/>
Аднарог згадваецца ў творчасці беларускай пісьменніцы Людмілы Рублеўскай, у тым ліку ёсць адсылка і да Слуцкай легенды.`,
    source:
      "Геральдыка Беларусі, А.Цітоў 2010; Дыярыуш пані, Л.Рублеўская; Аповед 'Апошні аднарог Еўропы', слуцкая легенда, 'Жаночы часопіс'",
  },
  {
    name: "Дабрахот",
    src: "Dabrahot",
    description: `Дабрахот, Дабрахожы, Дабрахочы - гэта вельмі цікавы міфалагічны персанаж беларускага фальклору. Дабрахот прывязаны да пэўнага месца. Першапачаткова мае выгляд Лесуна, або Палевіка, залежачы ад таго дзе жыве, у полі ці лесе. Але калі чалавек вырашае на гэтым месцы пабудаваць сабе дом і абжыць яго, то Дабрахот прымае выгляд Хатніка, або Хлеўніка. Часцей пузатага старога невялікага росту з сівымі вусамі ды барадой.<br/><br/>
Калі чалавек добры гаспадар, то і Дабрахот ставіцца да яго добра, дапамагае. Але калі чалавек дрэнна вядзе гаспадарку і запускае месца дзе жыве Дабрахот, то чакай бяды.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Дзікія людзі",
    src: "Dzikie-Ludzi",
    description: `Дзікія людзі, Дзікі народ - у фальклоры беларусаў Гарадзенскіх і Беластоцкіх зямель гэта людзкі народ, які жыве ў заморскіх лясах, парос поўсцю і забыўся чалавечую мову. На Ваўкавысскіх землях лічылі, што Дзікія людзі маюць толькі адно вока, ядуць людзей і пьюць барановую кроў.<br/><br/>
Дзікі чалавек таксама вельмі папулярны персанаж на еўрапейскіх сярэднявечных гербах, звычайна яго малявалі з вялікай дубінай.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Гаёўка",
    src: "Hajouka",
    description: `Гаёўкі - у беларускім фальклоры лясныя духі ў абліччы маладых русых дзяўчат. Яны любяць усё жывое ў лесе. I лось, і лісіца, і маленькая птушка, калі захварэюць або параняцца звяртаюцца да Гаёвак па дапамогу, бо ведаюць, што яны ім заўсёды дапамогуць. На зіму Гаёўкі пакрываюцца снежна-белай поўсцю. Людзі лічаць, што Гаёўкі гэта ўнучкі лесуна Гаюна.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Гаюн",
    src: "Hajun",
    description:
      "Гаюн, Гаёвы Дзед - у беларускай міфалогіі лясны дух вельмі падобны да Лясуна, ён гаспадарыць у дуброве, чарналессі або ў гаі.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Алень Сьв. Губерта",
    src: "Hubert-Deer",
    description: `Алень Сьв. Губерта - гэта міфічная істота мае ўскоснае дачыненне да Беларусі, бо першапачаткова з'яўляецца ў Бельгійскай легендзе пра паляванне біскупа Губерта Льежскага. Заблукаўшы ў лесе, Губерт сустракае аленя з бліскучым крыжам над галавой, алень паказвае яму выратавальную сцежку. У гэтым сэнсе гэта дух супрацьлеглы Туросіку, які наадварот заваблівае паляўнічых у балота, дзе яны гінуць.<br/><br/>
У 16 стагоддзі каралева Бона Сфорца выдае Беларускаму гораду Гродна герб з выявай Аленя Сьв. Губерта - апекуна паляўнічых. У тыя часы паляванне адно з асноўных заняткаў у Гродна, бо побач Гродзенская і Белавежская пушчы. Гарадзенскія паляўнічыя відавочна верылі ў чароўнага аленя, а кагосьці, магчыма, ён выратаваў і ў Гродзенскіх лясах.<br/><br/>
Таксама існуе цікавая легенда пра Пані Коханку - князя Радзівіла, які пад час палявання сустрэў дзіўнага аленя, на галаве якога паміж рог расло маленькае дрэва.`,
    source:
      "Народныя славянскія аповеды, І.Барычэўскі 2017; Легенда пра Сьв. Губерта;",
  },
  {
    name: "Камаедзіца",
    src: "Kamaedzica",
    description: `Камаедзіца - беларускае народнае свята, звязанае з сустрэчай вясны, лічыцца праявай татэмізму. Уключае ў сябе шэсце пераапранутых з удзелам мядзведзя і святочны абед, імітуюцца звычкі жывёлы. Святкуецца дваццаць чацвёртага сакавіка.<br/><br/>
На свята прынята было адносіць у лес крыху гарохавай кашы, быццам на пачастунак мядзведзю, які прачынаецца пасля зімы. Гарохавая каша зліпалася ў камы. Адсюль магла пайсці і назва свята: ”камаедзіца” - ”камы есці”. Ёсць гіпотэза, што ”кам” - старажытная назва гаспадара. Гэтак жа маглі зваць і мядзведзя як ”гаспадара лесу”, або Кам - галоўны з ўсіх мядзведзеў. У беларускім фальклоры мядзведзь гэта сакральная і татэмная жывёла, існуе шмат паданняў, дзе раней мядзведзь быў чалавекам, але за яго любоў да мёду быў ператвораны ў жывёлу.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Капялюшнік",
    src: "Kapialushnik",
    description:
      "Капялюшнікі - у фальклоры Віцебшчыны чалавекападобныя істоты, якія падобныя па сваіх паводзінах да блукаючых агеньчыкаў. Носяць вялікія капалюшы і могуць ператварацца ў птушак.",
    source: "Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Касны",
    src: "Kasny",
    description:
      "Касны - у беларускай міфалогіі дзейсныя выключна зграяй вупырападобныя істоты нявызначанага аблічча. Касны  дзейнічаюць абавязкова гуртам. Напаўшы на ахвяру, яны прысмоктваюцца да яе, нібы п'яўкі, і не пакідаюць, пакуль не давядуць да смерці. Касны прыстаюць да ахвяры тым з большай сілаю і злосцю, чым больш тая супраціўляецца. Злоба ап'яняе Каснаў.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Кук",
    src: "Kuk",
    description:
      "Як і ў многіх іншых міфалогіях, у Беларускай таксама ёсць кароль усіх птушак - велізарная птушка Кук. Паводле вераванняў беларусаў Кук абараняў птушак ад іншых драпежных жывёл. Аднак з часам ён знік і ніхто не ведае куды ён паляцеў, толькі зязюля ўсё яго шукае, гукае. У маім уяўленні гэта сумесь арлана белахвоста з арлом-вужаедам, з крыламі ад жураўля і афарбоўкай чорнага бусла.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Купалле",
    src: "Kupala",
    description: `Купалле - адно з найстаражытных і найважнейшых свят у беларускім фальклоры ды міфалогіі, звязанае з летнім сонцастаяннем.<br/><br/>
Лічылася, што ў гэты дзень прырода ажывае і пачынаецца разгул нячыстай сілы. Менавіта ў ноч на Купалле ажываюць усе дрэвы, яны пачынаюць перагаворвацца адно з адным, а таксама ў гэтую ноч пачынае квітнець легендарная Папараць-Кветка.<br/><br/>
Той хто знойдзе такую кветку, завалодае магічнай сілай, але зрабіць гэта вельмі складана, бо нячысцікі будуць старанна замінаць зрабіць гэта. Ды і лясны дух - Купальскі Дзядок, заўсёды на крок наперадзе, менавіта ён у гэтую ноч забірае практычна ўсе магічныя кветкі.<br/><br/>
Адна з галоўных традыцый беларусаў у гэтую ноч гэта паліць вялізныя вогнішчы, вадзіць вакол іх карагоды і скакаць праз агонь. Дзяўчыны ў гэтую ноч варожаць на суджанага і пускаюць вянкі па вадзе. Таксама гатуецца абрадавая ежа - верашчака, яечня, варэнікі.<br/><br/>
Ведзьмы, вядзьмары і чэрці збіраюцца на свае скокі на Лысыя Горы. Поўзаюць па хлявах у абліччы гадаў і крадуць малако ў кароў і коз. Могуць у гэтую ноч нават каня скрасці. Карацей, тая яшчэ ночка.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Купальскі Дзядок",
    src: "Kupalsky-Dziadok",
    description:
      "Купальскі Дзядок - лясны дух, які на Купалле блукае па лясах і збірае чароўныя папараць-кветкі. Менавіта з-за яго так складана знайсці такую кветку. Згодна Беларускаму фальклору, кветка прынясе ўладальніку неверагодную сілу і веды. Каб не страціць кветку і схаваць яе ад нячыстай сілы, яе трэба ўшыць пад скуру.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Лагішынскім воўк",
    src: "Lagishyn",
    description: `Ці знаёмыя вы з Лагішынскім ваўком? Пра гэтую беларускую міфічную істоту практычна няма дадзеных. Не захавалася ніводнай легенды ці міфа. Гэты звер вядомы толькі тым, што яшчэ са сярэднявечча ён адлюстроўваецца на гербе Лагішына.<br/><br/>
Толькі ў XVI стагоддзі Лагішын атрымаў магдэбурскае права і што цікава, герб у яго ўжо быў. У XVII стагоддзі Кароль і Вялікі Князь Уладзіслаў Ваза толькі пацвердзіў гэты мясцовы герб: "у сінім полі воўк на ласіных нагах".<br/><br/>
Міфічныя істоты, якія складаюцца з частак цела розных жывёл з'яўляліся ў геральдыцы і раней: грыфон, пегас, кентаўр. Яны ўсе вельмі папулярныя, але Лагішынскі воўк з'ява ўнікальная, ён прысутнічае на гербе толькі Лагішына і першае пісьмовае згадванне і выява ваўка з ласінымі нагамі датуецца 1633 годам.<br/><br/>
Вызначана гэта выява неяк звязана з мясцовымі легендамі, відаць, падобныя істоты калісьці жылі ў лясах каля Лагішына. Але акрамя знешняга выгляду, пра іх больш нічога не вядома.`,
    source: "Герб мястэчка Лагішын;",
  },
  {
    name: "Лесавая",
    src: "Lesavaja",
    description:
      "Лесавая або Лешачыха - у беларускім фальклоры гэта жонка Лясуна. Гэтую істоту рэдка можна сустрэць у лесе, часцей яна сядзіць у непраходнай часцы лесу і займаецца хатнімі справамі, гадуе дзяцей Лясуна. Але, часам, Лешачыха можа прабірацца ў жылыя хаты і падмяняць чалавечае дзіця на сваё капрызнае і непаслухмянае. З часам дзеці ператвараюцца таксама ў Лясуноў. Знешне Лешачыха выглядае як і Лясун, нейкая раслінная істота з адвіслымі грудзьмі, якія часам можа закідваць сабе за плечы.",
    source: "Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Лясун",
    src: "Liasun",
    description: `Лясун, Лесавік, Лешы, Лясны дзед - у славянскай і ў прыватнасці ў беларускай міфалогіі - гаспадар лесу. Абсяг дзейнасці Лесуна - уся лясная плошча ад узлеску да ўзлеску, за выключэннем вадаёмаў, дзе гаспадарыць Вадзянік. У глыбіні гэтай тэрыторыі знаходзіцца дом Лесавіка, дзе жывуць яго жонка і дзеці. Сам жа ён дома не сядзіць - больш вандруе, адпачывае і начуе ў выпадковых мясцінах: на вершалінах высокіх дрэў, у дуплах, густых зарасніках, у ламаччы, бураломах, у лагчынах і прадоннях.<br/><br/>
‌Лясун можа як напужаць чалавека, заблыдаць яго па лясным сцежкам, так і наадварот, дапамагчы выйсці з лесу.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Міфалогія роднага краю, А.Ненадавец 2018;",
  },
  {
    name: "Поднор",
    src: "Padnor",
    description:
      "Паднор, Поднор - паводле павер'яў беларусаў, гэта кароль ўсіх мышэй (лясных, палявых, хатніх). Да нашых дзён дайшло мала інфармацыі пра тое, як ён выглядаў. На мой погляд, ён чымсьці падобны на еўрапейскага Пацучынага Караля - велізарную рухомую масу пацукоў, зплеценых хвастамі. Уверсе, там дзе знаходзіцца умоўная галава, узвышаецца мыш буйнейшая за іншых з прыроднай каронай з костак. Сустрэча за Поднорам - знак бяды, хутка смерці.",
    source:
      "Шляхціц Завальня, Я.Баршчэўскі 1844; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Праява",
    src: "Prajava",
    description:
      "Праява - у беларускай мове, а таксама ва ўкраінскай, гэтым словам вяскоўцы называлі нейкую містычную істоту, пачвару, монстра або прывіда. У дадзеным выпадку гэта лясная праява, якая вылазіць з дрэва хапае жывёлу або чалавека і зацягвае яго назад у дрэва, дзе забівае. Чым больш ахвяр зацягне, тым таўсьцейшым ды вышэйшым вырасце дрэва. Праявы таксама часцей могуць з’яўляцца бліз вялікіх курганоў.",
    source: "Фольклорное наследие белорусов, П.Шпилевский 2020;",
  },
  {
    name: "Лядашцік",
    src: "Psucen",
    description: `Лядашцік, Псуцень, Шэўрадзь - у беларускім фальклоры злы дэман, які псуе людзей, робіць іх звар’яцелымі. Выглядаў як брудная пачвара, з крыламі, аднім ‌вокам і рудымі валасамі па ўсім тулаве. <br/><br/>
Больш за ўсё Лядашцік не любіў жанчын, за тое што тыя нараджаюць дзяцей і тым самым працягваюць чалавечы род. Той хто загляне ў ззяючае вока дэмана, у той жа час вар’яцее. Псуцень таксама можа пераўтвараць людзей у жывел, або рабіць іх абыякавамі да жыцця, такіх людзей пасля называюць Ныдзень.`,
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Пушчавік",
    src: "Pushchavik",
    description:
      "Пушчавік - лясны дух, гаспадар некранутай часткі лесу - пушчы. У лясной іерархіі старэйшы за Лесуна і Гаюна. Часцей за ўсё варожы чалавеку, бо той вінаваты ў высечцы лесу. Выглядае істота як нешта неверагодна высокае, вышэй шматлікіх дрэў, пакрытае карой і мохам.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Камень-кравец",
    src: "Stone",
    description: `Камень-кравец, Камень-шавец / Камяні-краўцы, Камяні-шаўцы - у беларускай міфалогіі валун, які нібыта меў цудадзейную сілу шыць людзям адзенне або абутак. У беларускім фальклоры ёсць шмат такіх паданняў з розных мясцін і краёў, часта паданні звязваліся з сапраўднымі валунамі, многія з якіх дажылі і да нашых дзён.<br/><br/>
Лічылася, што валун гэта быццам бы хата без дзвярэй, але з сакрэтнай адтулінай - акенцам, праз якое кравец або шавец прымае заказы за грошы. У адных паданнях у валуне жыве Цмок, у іншых Чорт, таму такія валуны яшчэ называлі Чортаў Камень. А часам было і невядома, хто ж гэты кравец...`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Беларускі фальклор, А.Сержпутоўскі, П.Шэйн, Е.Раманаў, К.Каганец 2022;",
  },
  {
    name: "Стрыга",
    src: "Stryga",
    description:
      "Стрыга - у заходне-славянскай міфалогіі ведзьма, нежыць, вупырыца, якая п'е чалавечую кроў. Міф аб стрызе сыходзіць каранямі ў рымска-лацінскi фальклор, у якім лічылася, што strix (звычайныя совы-сiпухi) высмоктваюць кроў у дзяцей.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Лясны цмок",
    src: "Tsmok",
    description:
      "Цмок - дракон у беларускай і польскай міфалогіі. У адрозненні ад заходняга еўрапейскага дракона, Цмок часцей ня варожы чалавеку, але ёсць і выключэнні. У Беларусі некалькі відаў Цмокаў, адзін з самых распаўсюджаных гэта лясныя. Цмок таксама можа пераварочвацца ў чалавека, часцей прыгожага юнака.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфы бацькаўшчыны, У.Васілевіч 1994; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Беларускі фальклор. Выпуск 8, 2021;",
  },
  {
    name: "Туросiк",
    src: "Turosik",
    description:
      "Туросiк - у беларускай міфалогіі дух у выглядзе тура (старажытнага быка) з залатымі рагамі, які сустрэўшыся ў лесе чалавеку, пакараў яго сваім выглядам. Чалавек, прываблены дзіўнай жывёлай, ішоў услед за Туросiкам і апыняўся ў непраходным балоце, з якога назад шляху няма.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Улішыца або Вуйма",
    src: "Ulishyca",
    description: `Улішыца або Вуйма - паводле беларускага фальклору лясны начны дэман жаночага полу, які мае чырвоныя вочы, вялікі да вушэй рот, пляскаты нос, хвост і поўсць каровы.<br/><br/>
На Купалле, калі саламяная лялька якую падпальвалі падала, то гэта было дрэнным знакам, тады на яе накідвалі пятлю і тапілі ў рацэ. Але часам ляльку выкідвала хвалямі на бераг, калі такая лялька праляжыць на беразе столькі месяцаў, колькі на святкаванні было людзей, то ажыве і ператворыцца ў дэмана Вуйму.<br/><br/>
Улішыца небяспечна для чалавека, яна любіць заманьваць людзей у сваё логава і замучваць іх там да смерці. Хутка можа перакідвацца ў дзіка з 6 нагамі. Часам Вуйма можа заходзіць на могілкі, падымаць з труны мерцвякоў і гутарыць з імі, нават яны могуць паплакаць разам, але зранку яна знікае, а нябожчык лезе назад у труну.`,
    source: "Народныя славянскія аповеды, І.Барычэўскі 2017;",
  },
  {
    name: "Верасовы",
    src: "Verasovy",
    description:
      "Верасовы, Верасовы дзед - у беларускім фальклоры гэта яшчэ адна назва Лясуна, які жыве на верасоўніку. Менавіта да Верасовага звяртаюцца ў замовах ад Начніц ды Крыкс. Ёсць у Верасовага і жонка - Верасовая баба.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Цмок Вяль",
    src: "Vial",
    description:
      "Вяль - у беларускай міфалогіі адзін з цмокаў. Вяль жыў каля валуна бліз вёскі Валэйкішкі (Астравецкі р-н), бавіўся з валуном, перакідваў і не даваў людзям прайсці, а таксама краў у іх скаціну. Аднойчы разумны вясковец закалоў барана, злупіў з яго скуру, заліў у яе смалу і серку і паставіў каля камня. Цмок убачыў барана і адразу з'еў. Гарачая смала заліла яму глотку, і ён адразу памёр.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Волат",
    src: "Volaty",
    description: `Волат, Валатоўна - персанаж беларускай міфалогіі. Асілак вялікага росту і магутнай сілы. Паводле паданняў, Волаты жылі ў старадаўнія часы, пахаваны ў доўгіх курганах — валатоўках.<br/><br/>
Ёсць цікавая легенда, звязаная з Мірскім Замкам. Падчас пабудовы замка і капання катлавана былі знойдзены велізарныя косткі. Людзі вырашылі, што гэта косці волата і навесілі іх на цэп брамы уязной вежы. Пайшла пагалоска, што замак пабудавалі волаты. У рэальнасці гэта былі косткі маманта.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992;",
  },
  {
    name: "Вужалка",
    src: "Vuzhalka",
    description:
      "Вужалка, Змяёўна - у Беларускім фальклоры напалову дзяўчыны, а на палову змеі. Дачкі Вужынага Караля. Вельмі любяць залатыя ўпрыгожванні - бранзалеты, завушніцы, грэбні, якімі чэшуць свае валасы. Таму, хто знойдзе такі прадмет, будзе заўсёды ўчасціць. Але сустрэць Вужалку цяжка, бо гады па загаду Вужынага Караля іх вельмі абараняюць. У беларускім фальклоры таксама ёсць згадкі пра Русалак са змяінымі хвастамі.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Вужыны Кароль",
    src: "Vuzhyny-Karol",
    description: `Вужыны Кароль, Вужыны Цар - гэта легендарная істота ў беларускай міфалогіі, буйная змяя з прыроднай каронай, якая валодала магічнымі ўласцівасцямі. Вужыны Кароль галоўны над усімі вужами, яшчаркамі і іншымі змеямі, захавальнік таемных скарбаў і ўладар магічных артэфактаў.<br/><br/>
Лічылася, што нават нязначны кавалак кароны, напрыклад адзін ражок, Караля Вужоў мог надзяліць ўладальніка рознымі неверагоднымі здольнасцямі: непаражальнасць ад ядаў, разуменне чужых думак, магчымасць размаўляць на мове звяроў, птушак ды раслін.<br/><br/>
Каб атрымаць кавалак кароны Вужынага Караля, трэба было перад ім разаслаць на дарозе прыгожы ручнік. Добры час для гэтага, калі Вужыны Цар вядзе ўсіх змей у Змяіны Вырай на зімоўку. Але, калі чалавек паспрабуе адабраць карону сілай ці хітрасцю, то ўсе змеі адразу накінуцца на яго і заб’юць.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Шляхціц Завальня, Я.Баршчэўскі 1844;",
  },
  {
    name: "Зазоўка",
    src: "Zazouka",
    description: `Зазоўка - дэманічная жаночая істота ў Беларускай міфалогіі. Выглядала Зазоўка як прыгожая маладая дзяўчына з надзвычай доўгімі валасамі, якія прыкрывалі яе галізну. Як правіла, яна заваблівала падарожнікаў у самы гушчар лесу, то паказваючыся, то хаваючыся, зазываючы падарожніка па імені, агаляючы сваё цела.<br/><br/>
Хтосьці гінуў у балотах, хтосьці дасягаў мэты і атрымліваў жаданае. Тыя падарожнікі, што пасля ласк Зазоўкі вярталіся дадому да жонак, потым зноў сыходзілі ў лес на яе пошукі і ўжо дадому ніколі не вярталіся.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
];

const swamp = [
  {
    name: "Аржавенік",
    src: "Arzhavenik",
    description:
      'Аржавенік, Аржавень - у беларускай міфалогіі дух, "старэйшы" або "галоўны" з трох відаў балотных нячысцікаў - Балотнік, Аржавенік і Багнік. Насяляў аржавень - балота з іржавымі плямамі на паверхні з-за растворанай у ім жалезнай руды.',
  },
  {
    name: "Ягіня",
    src: "Baba-Yaga",
    description: `Гэтага персанажа народнага фальклору і апісваць не трэба. Ягіня, Баба Яга, Ятага - агульны для ўсіх славян фальклорны персанаж. У беларусаў часцей адмоўны. Выглядае як жудасная старая з вялікай колькасцю магічных артэфактаў. Жыве ў хаце ў глыбі леса, уся хата застаўлена бочкамі з крывёю, а на плоце вісяць чалавечыя косткі.<br/><br/>
Ягіня галоўная з усіх вядзьмарак, іншыя лётаюць да яе за саветам і настаўленнямі. Яна як бы і на гэтым свеце і адначасова на тым, сваёй касцяной нагой. За плячыма ў яе Разамара - яе памочнік, касматы нячысцік з крыламі, які носіць ёй у лес малых дзяцей.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002;",
  },
  {
    name: "Багнік",
    src: "Bagnik",
    description:
      "Багнік - у беларускай міфалогіі гэта дух, адзін з трох відаў балотных нячысцікаў - Балотнік, Багнік і Аржавенік. Жыве у багне - тарфяным балоце, якое ніколі не пакрываецца расліннасцю і выглядае як бруднае чорнае возера.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Балотнік",
    src: "Balotnik",
    description:
      "Балотнік - злы нячысцік, які жыве ў балоце, прываджвае падарожнікаў да балота і зацягвае іх на самае дно. Балотнікі ў міфалогіі Беларусі, гэта як тролі ў міфалогіі Нарвегіі, наша тэрыторыя амаль уся пакрыта балотамі. Згодна беларускаму фальклору Балотнік малодшы з трох дэманічных істот гаспадарачых на балотах, старэйшыя - Аржавенік і Багнік.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Чорт",
    src: "Chort",
    description:
      "Чорт - як жа без яго, гэта адна з самых папулярных істот у славянскай міфалогіі. Гэта злы дух, які шкодзіць людзям, выгандлёўвае іх душы. Валодае магічнымі здольнасцямі, можа ператварацца ў жывёл старажытных культаў - казла, сабаку, ваўка, крумкача, змяю, а таксама іншых людзей, сцеражэ скарбы на балотах, у пячорах, або ў глыбіні леса.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Беларускі фальклор, А.Сержпутоўскі, П.Шэйн, Е.Раманаў, К.Каганец 2022; ",
  },
  {
    name: "Цыцоха",
    src: "Cycoha",
    description:
      "Цыцоха - у фальклоры Заходняга Палесся паўбабы-паўжабы з жалезнымі грудзьмі, якія лавілі непаслухмяных дзяцей і душылі іх, засоўваючы ў рот ахвяры свае «цыцкі».",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Беларускі фальклор. Выпуск 3, 2016;",
  },
  {
    name: "Цыгра",
    src: "Cygra",
    description:
      "Цыгра - мiфічная iстота, якою страшылi дзяцей, каб яны не лазілі дзе ні трэба - лес, балота, чужы агарод. Мабыць гэта бліжайшы сваяк Цыцохі, але душыць дзяцей яна каменнымі, а не жалезнымі цыцкамі. Згадваецца на ўсходзе Беларусі.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Зямная дарога ў вырай: Беларускія народныя прыкметы і павер'і, У.Васілевіч 2010 ",
  },
  {
    name: "Халера",
    src: "Halera",
    description:
      "Халера - увасабленне аднайменнай хваробы ў беларускім фальклоры. Людзі ўяўлялі сабе гэтую істоту ў выглядзе непрыгожай худой старой з цёмным, нават сінім, як курыны пуп, тварам. Гэтыя злыя пачвары хадзілі ў бліжэйшыя вёскі і тоўпамі забівалі людзей.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992;",
  },
  {
    name: "Хапун",
    src: "Hapun",
    description:
      "Хапун - у беларускім фальклоры злая лятучая пачвара з доўгай барадой. Лічылася, што хапун выкрадае людзей, у прыватнасці дзяцей, і выносіць іх на непраходнае балота, дзе знаходзіцца яго логава. Там з цягам часу закладнікі таксама ператвараліся ў хапуноў.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Хата смерці",
    src: "Hata",
    description: `Хата смерці, Дамавіна і інш., Хата на курыных ножках - легендарнае міфічнае месца ў славянскай міфалогіі, якое, як правіла, знаходзіцца ў самым гушчары лясу. Хата смерці з'яўляецца праходам у іншы свет, свет мёртвых.<br/><br/>
У дахрысціянскія часы людзей хавалі ў невялікіх драўляных хатках без вокнаў, у хатку людзі складалі ўсе рэчы, якія маглі б спатрэбіцца нябожчыку ў свеце мёртвых. Такія хаткі часцей ставілі на пнях з-за балоцістай мясцовасці, папярэдне апрацоўваючы іх агнём - акурваючы, адсюль і курыныя ножкі. Сам пень са сваімі каранямі таксама мог нагадваць курыныя ногі.<br/><br/>
Адметнай асаблівасцю Хаты смерці ў беларускім фальклоры з'яўляецца тое, што ў ёй не заўсёды жыве Баба Яга, ёсць апавяданні, дзе ў Хатцы на курынай ножцы жывуць браты Ваўкалакі.`,
    source: "Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Кадук",
    src: "Kaduk",
    description:
      "Кадук, Куйдук - адна з самых злых цёмных істот у фальклоры Беларусаў. Увасабленне хваробы эпілепсіі. Кадук жыве ў самых непраходных месцах на балоце, яму часта прыслугоўваюць іншыя дэманічныя істоты і нячысцікі. Па яго загаду яны зацягваюць ахвяр у яго логава. Калі чалавек трапляе на такое балота, то на яго находзіць быццам бы чароўны дурман: яму здаецца, нібыта ён ходзіць, а на самой справе стаіць на адным і тым жа месцы.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992;",
  },
  {
    name: "Каўтун",
    src: "Kautun",
    description: `Каўтун, Калтун, Госцец - у беларускім фальклоры гэта злы дух, дэман, персаніфікацыя аднаіменнай хваробы. Знешне выглядала ўсё так, што ў чалавека былі збітыя, заблытаныя ў адзін ком валасы, быццам бы поўсць. ‌Маглі быць і больш цяжкія сімптомы: боль, ламаццё ў касцях, сутаргі, скураныя язвы, высып, псіхічныя расстройствы. Каўтун мог трапіць у чалавека праз Сурокі, разам са злым ветрам - Падвей, або нават з кажаном, які мог трапіць у валасы і заблытацца ў іх.<br/><br/>
Людзі не спяшаліся пазбавіцца ад Каўтуна, часам лічылася, што гэта добры знак і Каўтун бароніць чалавека ад іншага зла, ад Чарцей. Каўтун на хатняй жывёле прыпісваўся дзеянням хатніх або дваравых духаў - Хлеўнік або Хатнік, і таму здымаць яго было забаронена, каб ненашкодзіць жывёле.<br/><br/>
Каўтун мог вырастаць да вялікіх памераў і чалавечыя пакуты станавіліся невыноснымі. Дэман мог перамяшчацца па ўсім целе чалавека. Пазбаўляліся ад Каўтуна рознымі шляхамі: замовамі, перабівалі кавалдай або малатком на камені, перапальвалі срэбным дротам. Нельга было выкарыстоўваць нож або нажніцы, у такіх выпадках можна было пабачыць кроў дэмана і ён мог перакінуцца на таго, хто яго зрэзаў.<br/><br/>
Потым Каўтун зпальвалі або закопвалі ў белай тканіне ў зямлю, каб ён не вяртаўся болей.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Кронак",
    src: "Kronak",
    description: `Кронак - гэта лакальны дэман беларускага балота Ельня. Месныя жыхары лічаць, што Кронак нейкім чынам трапіў у наш свет з іншага вымерэння ў ноч на Купалле і ня змог вярнуцца, таму што нехта выбіў яму вочы вогнішчам.<br/><br/>
Агідная пачвара з чорнай скурай амаль без валасоў, тры метры ростам з доўгімі да зямлі рукамі. Усё цела пакрыта язвамі і нарывамі, поўнымі чорнага слізу. Калі гэты сліз пападае на нешта жывое, напрыклад расліну або дрэва, то яно гіне. Галава Кронака быццам конскі чэрап, абцягнуты чорная скурай з плямамі валасоў і доўгімі вострымі зубамі. На галаве расце рог у якім нават пасля смерці дэмана захоўваецца цёмная сіла.<br/><br/>
Кажуць, хто знойдзе косткі мёрцвага дэмана і забярэ рог, той потым звар’яцее. Апошні раз, вядома, што рэшткі дэмана бачылі на праклятым Матруніным востраве.`,
    source: "Лакальныя легенды балота Ельня",
  },
  {
    name: "Лазавік",
    src: "Lazavik",
    description: `Лазавік або Лазавы дзед - у беларускім фальклоры гэта дух які жыве ў лазе і  выглядае як аднавокі стары невялікага росту. Ноччу яго вока ззяе і прываблівае чалавека. Калі лаза расце каля вадаёму, то Лазавіка людзі могуць блытаць з Вадзянікам, калі на балоце - з Балотнікам.<br/><br/>
Сам Лазавік добра ставіцца да людзе і можа нават дапамагчы чалавеку ўцячы ад Вадзяніка або Балотніка. Таксама ў Лазавіка ёсць чароўная пуга, але ніхто не ведае навошта яна, мабыць пугаць Лознікаў. Лознікі гэта такія гарэзлівыя чэрці такога ж колеру, што і лаза, яны таксама жывуць у лазе, у цэлым яны бяспечныя для чалавека, але часам могуць завесці таго ў багну да больш буйнейшых нячысцікаў.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Ламец",
    src: "Lamec",
    description: `Ламец - у беларускім фальклоры злы балотны дэман. Гэта ліхі дух, вера ў якога была на Палессі, згадваецца ў выслоўях і праклёнах на Тураўшчыне.<br/><br/>
Ламец бязлітасны да простых людзей. Яго магічныя абдымкі зачароўваюць, пасля чаго дэман душыць чалавека, ламае яму ўсе косці. Але пры гэтым, Ламец абыходзіць бокам валодання чарцей, ён не звязваецца з тымі, хто ім прыслугоўвае - ведзьмамі ды ведзьмакамі.<br/><br/>
Кажуць Хапун хапае, Кадук кідае (маецца на ўвазе прыступ эпілепсіі), Лізун зліжа, а Ламец зламае...`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Трасца і сёстры Ліхаманкі",
    src: "Lihamanki",
    description: `Трасца і сёстры Ліхаманкі. Ліхаманкі - у беларускай міфалогіі дэманічныя постаці хваробы, якія ўяўляліся ў выглядзе 7, 9, 12, 33, 40 і нават 77 сясцёр. Звычайна Ліхаманкі жывуць каля рэк і балот, але час ад часу лётаюць у паветры ды трапляюць у ежу, хто яе пакаштуе, той захварэе ліхаманкай.<br/><br/>
Могуць Ліхаманкі і ператварацца ў маладых і спакуслівых жанчын, або наадварот, хударлявых і пачварных старых, яны ходзяць па начах, грукаюцца ў вокны, клічуць людзей на імя - хто адгукнецца, да таго яны чапляюцца і прыносяць пакуты. Напэўна самая вядомая з Ліхаманак гэта сястра Трасца, а таксама Хіндзя, Хондзя, Незбудзь, Фыбра, Шуня і інш.<br/><br/>
Каб пазбавіцца ад хваробы, людзі прыносілі ёй ахвяру ў выглядзе варанага яйка ад чорнай курыцы. Таксаба рабілі абярэгі лялькі-ліхаманкі-трасавіцы.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992;",
  },
  {
    name: "Ліхтарка",
    src: "Lihtarka",
    description:
      "У беларускім фальклоры ёсць істоты найстаражытнейшыя, чым Балотнік або Лясун - агні блакітнага, белага або зялёнага колеру. Нават старэйшыя з вясковых няведаюць іх імён і мовы. Часам людзі называюць іх Ліхтаркі або Блукаючыя агеньчыкі, Балотныя агні. Часцей за ўсё іх можна пабачыць на балотах, але таксама можна сустрэць ноччу ў полі або на могілках. Адныя Ліхтаркі заводзяць чалавека ў самае балота і забіваюць яго, іншыя могуць дапамагчы выйсці з багны, а часам нават знайсці скарб.",
    source:
      "Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Шляхціц Завальня, Я.Баршчэўскі 1844;",
  },
  {
    name: "Лойма",
    src: "Loima",
    description:
      "Лойма - нячысцік беларускага фальклору, істота падобная на русалак, але жыве часцей на балотах і ў лясах. Лоймы небяспечней русалак і больш злосныя ў адносінах да людзей. Некаторыя кажуць, што яны паўстаюць у вобразе прыгожых дзяўчат, іншыя, то што яны брыдкія, могуць ператварацца ў вялікіх жаб. Таксама лоймам прыпісваюць крадзеж і падмену дзяцей.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Маравая Панна",
    src: "Moravaja-Panna",
    description: `Маравая Панна, Моравая Панна, Маровая Панна. У беларускім фальклоры перад надыходам мора (хваробы), часам з'яўляецца Маравая Панна - страшная выродлівая старая, якая, лётаючы у паветры, махае чорнай хусткай. Над той вёскай, дзе яна памахае, у той вёсцы і праявіцца мор. Часам яна махае хусткай, прасунуўшы руку ў вокны якой-небудзь хаты, і тады ў гэтай хаце пачынаюць паміраць людзі.<br/><br/>
Каб прагнаць Маравую Панну, маладыя дзяўчаты запрагаліся ў саху, а старыя бралі ў рукі шпакі і косы, а пасля распрануўшыся донага, з крыкамі, віскам і шумам абводзілі баразну вакол вёскі. Абрад ставіў за мэту напалохаць пачвару і прагнаць яе з вёскі. Мужчыны ў шэсці не ўдзельнічалі, але калі нехта з іх трапляўся падчас шэсця, то, як правіла, ён быў моцна збіты працэсіяй. Падобны рытуал знік у Беларусі ў пачатку XIX ст.`,
    source: "Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895;",
  },
  {
    name: "Наўкі",
    src: "Nauki",
    description: `Наўкі, Наўцы, Наўе - у беларускай міфалогіі гэта духі нябожчыкаў, часцей нядобрых людзей, тых чые душы былі прададзены або скрадзены нячыстай сілай. Наўцы адмоўна настроены ў адносінах да жывых людзей і жывуць у падземным свеце з мноствам падземных хадоў ды лёхаў, які ад свету жывых аддзяляюць рэкі, азёры і балоты.<br/><br/>
Так, паводле Радзівілаўскага летапісу "Аповесце мінулых гадоў", у 1092 годзе вялікая колькасць Наўцаў напала на горад Полацк. Людзі не маглі іх бачыць, толькі чуць і бачыць сляды ад коней. Тыя, да каго дакраналіся Наўцы, паміралі страшнай смерцю ад хвароб і язваў.<br/><br/>
Тым не менш у беларусаў ёсць старое свята, звязанае з гэтымі духамі - Наўскі Чацвер ці Вялікдзень Мёртвых.`,
    source: "Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Паморак",
    src: "Pamorak",
    description: `Паморак - у беларускай міфалогіі злы дэман, персаніфікацыя аднаіменнай з'явы — масавай смерці людзей і жывёл. Мог уяўляцца ў выглядзе жывелы - ката, сабакі, або чалавека - худой дзяўчыны з цемным тварам ці жудаснага аднавокага старога з касматай барадой.<br/><br/>
Ператварыўшыся ў чалавека, паморак імкнуўся пранікнуць ў сяло, дзе людзі яшчэ не хворыя: то прасіў селяніна падвезці яго на возе да вёскі, то пераправіць праз раку. Паморак прабіраўся ў сяло і пачынаў знішчаць людзей.<br/><br/>
Полымя, здабытае ад трэння, лічылася моцнай зброяй супраць Паморка. Людзі распальвалі па канцах сяла вогнішчы, каб гарэлі дзень і ноч, каб дэман не мог прабрацца ў хаты. Ад тых вогнішчаў людзі бралі агенчыкі і заносілі ў хату, стараліся ўвесь час падтрымліваць іх.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992;",
  },
  {
    name: "Паралікі",
    src: "Paraliki",
    description: `Паралікі - у беларускай міфалогіі гэта ліхія духі, якія ў адрозненне ад іншых нячысцікаў апаноўваюць ахвяру не адразу, а падступаюцца да яе спадцішка, часам на працягу некалькіх гадоў. Часцей іх ахвярамі становяцца людзі сталага ўзросту. Дзейнічаюць паралікі гуртам, але не так як Касны, хутчэй як Паветрыкі, працуючы папераменна, а вось пры супраціўленні ахвяры - ужо разам.<br/><br/>
Чалавек пазнае прысутнасць Паралікоў толькі тады, калі яны зжыліся з ім, і адпрэчыць іх ужо няма ніякай магчымасці. Выглядаюць гэтыя нячысцікі жудасна: каўматыя, касыя, з рознымі рукамі і чортавымі нагамі. Даймаюць галоўным чынам цела чалавека, а з ім іх ахвяраю становіцца і яго душа.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Прахі",
    src: "Prahi",
    description:
      "Прахі - у беларускім фальклоры істоты з касты чарцей. Нешта накшталт нячысцікаў-пачаткоўцаў або малодшых чарцей. Яны не асабліва небяспечныя для чалавека, бо ўвесь час дрэнна выконваюць даручэнні старэйшых братоў. Нягледзячы на тое, што яны як і іншыя нячысцікі ды дэманы не любяць людзей, па сваёй волі шкоды чалавеку яны не наносяць. Часцей Прахі з-за дрэнна выкананага даручэння самі церпяць расправы ад старэйшых чарцей.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Радзімец",
    src: "Radzimec",
    description: `Радзімец - у беларускай міфалогіі страшэнны дэман, які мог усяляцца ў чалавека і выклікаць у яго пакутлівыя прыпадкі. Радзімец часцей усяляўся ў дзяцей, таго ж дэмана, які ўсяляўся ў дарослых, беларусы называлі Падучы.<br/><br/>
У беларусаў было мноства рытуалаў, як абараніцца ад дэмана або прагнаць яго. У тое месца на зямлі, дзе адбыўся прыпадак, убівалі асінавы кол ці нож, які падчас прыпадку быў уваткнуты ў зямлю над галавой хворага. Быў таксама рытуал звязаны з крывёю. Падчас прыпадку над хворым рэзалі маладую курыцу і цёплай крывёю абмазвалі грудзі, рот і зубы хворага. Таксама выкарыстоўвалі кроў ката, кажана, крата або чорнага пеўня. Адганялі дэмана і падманам, калі сям'я думала, што дэман на падыходзе да іх жылля, трэба было высадзіць дзіцяці праз акно на вуліцу. Дэман уваходзіў у хату і не пабачыўшы дзіцяці сыходзіў шукаць яго ў іншую хату, у гэты момант дзіця праз акно вярталі назад ў хату.<br/><br/>
Васіль з вёскі Мышанка страціў дзіця з-за дэмана Радзімца. Забіты горам і асляплёны нянавісцю ён адправіўся ў непраходную багну, каб адпомсціць. У глыбіні балотных тунэляў і лёхаў ён знайшоў логава Радзімцаў. Васіля з таго часу больш ніхто не бачыў...`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Шэшка",
    src: "Sheshki",
    description: `Шэшка, Шэшачкі, Шэшкі - у беларускай міфалогіі разнавіднасць чарцей. Вельмі вольныя, бестурботныя, па-дзіцячаму гарэзныя. За гэта і за маленькія памеры (не большы за котку) іх называлі ласкава - Шэшачкі.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Смаляны Чорт",
    src: "Smaliany-Chort",
    description: `Смаляны Чорт - у беларускім фальклоры гэта асаблівая разнастайнасць чарцей, якія апякуюць смаляную здабычу, што было тыпова і натуральна для нашай балоцістай мясцовасці, асабліва на Палессі. Уначы Смаляны Чорт любіць з'явіцца ў абліччы чорнага казла, днём - пад выглядам кіраўніка смалярні, а насамрэч ён выглядае, як усе звычайныя чэрці і асноўнае месца яго пасялення гэта смалярня.<br /><br />
Смалярня (ці інакш "буда") уяўляе сабой спецыяльна абсталяваную печ для вытворчасці дзёгцю, паташу, перагонкі смалы і вытворчасці вугалю.<br /><br />
Лічылася, што без чорта ў падобным рамястве ніяк. Той хто хваліцца і выхваляецца, што без чорта абыйдзецца ў працы на смале, той яму зараз жа і выкіне якую-небудзь чортаву штуку. Ведай, маўляў, што без мяне нічога не зробіш тут.<br /><br />
Смаляны чорт прыглядвае за смалой нават тады, калі яе ўжо перавозяць у бочках да прыстані, каб адправіць на продаж.`,
    source: "Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Сурокі",
    src: "Suroki",
    description: `Сурокі, Прыстрэкі - у беларускім фальклоры гэта хвароба, псота або няшчасце, якое выглядае як нейкая жывая аморфная істота, выкліканая праклёнам вядзьмаркі або чараўніка. Часам можа быць наступствам "дурнога вока".<br/><br/>
Вядзьмак нават няўмысна можа пусціць чароўнае слова з ветрам, якое нясе Сурокі і шукае ў каго трапіць, калі яно нікога не спаткае, то ў дрэва трапляе. Потым гэтае дрэва сохне і гіне. Сурокі можа прынесці і прадстаўнік жывёльнага свету: конь, воўк, котка, мыш, верабей або карова.<br/><br/>
Дапамагчы хвораму і прагнаць Сурокі назад на балота можа добры знахар. Часткай такога абраду могуць з’яўляцца чацвярговая соль або перуновы стрэлы. Ёсць і замовы да Лясуна або Палевіка, каб дапамаглі перамагчы хваробу.<br/><br/>
Таксама ёсць і абярэгі ад Сурокаў, напрыклад, усім вядомая чырвоная нітка. Лічылася, што яна адганяе такія праклёны.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Жалезны чалавек",
    src: "Zalezny-Chalavek",
    description:
      "Жалезны чалавек, Залезны чалавек - паводле павер'яў Беларускага Палесся гэта чалавекападобны волат, цалкам які складаецца з жалезнай руды. Жыў на балотах або ў забалочаных лясах, якія рупліва ахоўваў ад мінакоў.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Сваяны",
    src: "Svayany",
    description: `"Сваяны - разнавіднасць Блукаючых агеньчыкаў ці Ліхтарак, якія насяляюць балота Ельня. Па расказах відавочцаў, гэта яркія шары малочнага колеру, якія лунаюць над зямлёй. Часцей Сваяны добразычлівыя да чалавека і з'яўляюцца каб дапамагчы выбрацца з балота. Яны паказваюць бяспечныя сцежкі, па якіх чалавек не трапіць у лапы Балотніка ці патоне ў багне.
<br/><br/>Мне пашчасціла таксама іх убачыць, я нават разгледзеў нейкую маленькую істоту ўнутры шара. Сваян настойліва даваў мне зразумець, што я павінен ісці ў іншы бок. Але ён не ведаў, што я не заблукаў, а наўмысна ішоў у глыб балота шукаючы сустрэчы з монстрам…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Змей",
    src: "Zmej",
    description:
      "Зьмей, Змей, Цуда-Юда - агульны для ўсяго славянскага фальклору адмоўны персанаж. Зьмей мае падобныя рысы з Цмокам: зьмяіная прырода паходжання, велізарныя памеры, крылы, здольнасць вывяргаць полымя з пашчы і перакідвацца ў чалавека. Любімы занятак Зьмея - гэта падпальваць вёскі і красці прыгожых дзяўчат. Ад апавядання да апавядання ў Зьмея можа быць адна, тры, шэсць, дзевяць і нават дванаццаць галоў. Істота гэтая адпаведна цёмная і злая.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Чарлёка",
    src: "Charnuha",
    description: `"Гэта дзіўна, але часам здараецца, што не толькі людзі да мяне звяртаюцца па дапамогу і параду, але і прадстаўнікі чароўнага свету.
<br/><br/>Па дарозе на поўдзень ад Пастаў мяне падвозіў мясцовы мужык на сваім абозе. Мне трэба было патрапіць у адну вёску непадалёк і я спешыўся, каб зрэзаць шлях лясной сцежкай.
<br/><br/>На лясной дарозе мяне раптам атачыла пяць-шэсць Красналюдаў з ўсіх бакоў. Аказалася гаворка пра мяне сярод іх народа таксама ходзіць і яны вельмі добра дасведчаныя чым я займаюся. Красналюды сачылі за мной ад саміх Пастаў, каб злавіць момант і папрасіць дапамогі.
<br/><br/>Недалёка ад вёскі, дзе яны жывуць, ёсць вялікае возера Швакшты. Тамака мясцовыя Красналюды пачалі выводзіць нейкі новы незвычайны выгляд жукоў — яны падобныя на жукоў-аленяў, толькі буйней і ззяюць ў цемры халодна-сінім колерам. Я не да канца зразумеў, навошта гэта ім, але тое, што яны вельмі каштоўныя і важныя для Красналюдаў - гэта дакладна.
<br/><br/>Побач з возерам ёсць некалькі балот з якіх увесь час выпаўзае "бяскрылы цмок" і знішчае фермы жукоў, ядучы іх. Аднойчы ён нават з’еў аднаго Красналюда-даглядчыка.
<br/><br/>Я паабяцаў дапамагчы, ды і цікава было ўбачыць бяскрылага цмока. У вёсцы Баяры я пагутарыў з людзьмі і даведаўся, што балотны монстар не цмок зусім.
<br/><br/>Чарлёка ці Чарнуха — гэта велізарны рэліктавы яшчар чорнага колеру, якіх раней тут жыло шмат. Людзі іх нават трымалі ў сябе на ланцугах замест сабак і падкормлівалі. Цяпер гэтая жывёла амаль знікла.
<br/><br/>Я не вельмі хацеў пісаць ліст Тэадору, але па частцы вялізных яшчараў ён ад Варшавы да Смаленска самы галоўны паляўнічы на іх. Ды і слова "цмок" прымусіць яго прымчацца ў любы забалочаны куток Беларусі.
<br/><br/>Чарлёка быў адлоўлены і забіты. Вонкава ён мне нагадаў заморскіх-кракадзілаў. А Тэадор расчаравана мне распавёў, што гэта не першы ўжо такі яго ўлоў. Такога ж монстра ён пару гадоў таму злавіў у Менску, тады вакол гэтага выпадку было шмат шуму з-за грамадскай агалоскі. Ну і што гэта зусім не цмок…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
  {
    name: "Жалезная баба",
    src: "Zhaleznaja-Baba",
    description:
        "Жалезная баба, Жэлезнячка - у беларускім фальклоры страшная нізкарослая жабападобная старая, з жалезнымі грудзьмі і жалезным гакам, якім яна хапае непаслухмяных дзяцей. Можа жыць у старых калодзежах, на палях і агародах. Па сваіх функцыях падобна да Цыцохі і Цыгры.",
    source:
        "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
];

const home = [
  {
    name: "Агнявік",
    src: "Agniavik",
    description:
      "Агнявік - хатні шкодны дух, увесь валасаты і кудлаты, жыве ў печы. Агнявік часта з нуды кідае вугольчыкі і попел у ежу, якая гатуецца на пячы.",
  },
  {
    name: "Апівень",
    src: "Apiven",
    description: `Апівень - гэта такі чорт, дэман які схіляе людзей да п’янства. Вяскоўцы лічылі, што ён ніколі не прамінае ніводнага застолля ці вяселля. Ён прыстройваецца дзе-небудзь на канцы стала і сочыць за тым, хто і колькі выпівае алкаголю. <br/><br/>
‌Не вельмі пітучых апівень падбухторвае, каб яны як мага больш выпілі. Калі яму гэта не ўдаецца -  Апівень падлівае ў чарку нейкага жоўтага зелля, пасля чаго чалавек робіцца зусім п'яны.<br/><br/>
Вельмі падабаецца Апіўню забаўляцца з п'яніцамі. Ён дражніць іх і скідвае пад стол, пры гэтым сам нячысцік нікому не бачны звычайна.`,
  },
  {
    name: "Баган",
    src: "Bagan",
    description:
      "Баган, Буган - дух-апякун рагатай жывёлы. Баган засцерагае гаспадарскіх жывёл (кароў, коз, авечак) ад розных хвароб, спрыяе паспяховым родам целяня, клапоціцца аб дзіцянятах, альбо, пры дрэнным да яго стаўленні - наадварот, заганяе і выводзіць жывёлу, робіць кароў і коз бясплоднымі. Людзі адводзяць Багану асаблівае месца ў хляве, сцелюць яму сена ў яслях, верачы што менавіта там ён селіцца.",
  },
  {
    name: "Белая Баба",
    src: "Belaja-Baba",
    description: `Белая Баба або проста Смерць - гэта дух смерці. Людзі ўяўляюць яе бледнай схуднелай жанчынай з састлелым тварам нябожчыка, амаль што шкілета, загорнутай у белы саван і намітку ці ў адзенне, падобнае чымсці да шлюбнага.<br/><br/>
Паўстае Белая Баба перад чалавекам нечакана, слупам вырастаючы з зямлі. Чалавеку гэтая сустрэча прадказвае хуткую смерць. Часам Белая Баба з’яўлялася нават у сам момант развітання з жыццём.<br/><br/>
Каб Белая Баба не ўвайшла ў хату і не прынесла няшчасце, пад ганкам закопвалі кабыліную галаву, вешалі касу над варотамі ці на верхнім вушаку дзвярэй, прыбівалі да парога падкову.<br/><br/>
Цалкам незразумела хто пасылае Смерць да чалавека, адныя лічаць, што гэта сам Бог - Бацька Неба, іншыя, што гэта Чорт. Таксама не зразумела ў якіх адносінах Белая Баба з іншымі духамі смерці: Ягіняй, Паляндрай, Копшай. Дакладна вядома толькі тое, што магільны Стораж, або Чаргавы, ахоўвае вёску ад Белай Бабы.`,
  },
  {
    name: "Будзімір",
    src: "Budzimir",
    description:
      "Будзімір - рэлікт якога лічаць каролём усіх пеўнеў, галоўны певень, які кожны дзень будзіць увесь свет. Будзімір пачынае раніцай сваю песню, якую па ланцугу падхватваюць усе іншыя пеўні на свеце.",
  },
  {
    name: "Чараўнікі",
    src: "Charauniki",
    description: `У беларускім фальклоры не толькі ведзьмы ды вядзьмары маюць здольнасці да чараўніцтва, пераўтварацца або пераўтвараць у ваўкалака, ці насылаць праклёны. Ёсць такія асобныя касты людзей, якія таксама маюць сувязь з нячыстай сілай ды чараўніцтвам: такой кастай з’яўляюцца дудары, млынары і пастухі.<br /><br />
Дудары - народ бывалы, многа чаго ведае, вандруючы па белу свету.<br /><br />
Млынары - народ адчужаны, нелюдзімы, большасць часу сядзяць на сваіх млынах, і ноччу дзе-нідзе, хто там ведае што за нячысцікі там снуюць. На вёсцы кажуць млынар - чалавек не просты, з Вадзянікам знаецца.<br /><br />
Пастухі ўсё лета па лясах  ды балотах ходзяць, збіраюць травы, сушаць карэнні, спрабуюць розныя цалебныя зеллі на скаціне, вось і кажуць што пастухі з Кадуком сяброўства водзяць.`,
    source:
      "Фольклорное наследие белорусов, П.Шпилевский 2020; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Магільны Стораж",
    src: "Chargavy",
    description:
      "Магільны Стораж, Чаргавы - магільны дух, здань апошняга чалавека, пахаванага на могілках. Просты люд мае веру, што Стораж засцерагае могілкі, абараняе сяло ад замахаў Смерці, адцягвае яе прыход. Функцыя Стоража заўсёды пераходзіць ад нябожчыка да нябожчыка.",
  },
  {
    name: "Доля",
    src: "Dola",
    description: `Доля - ўвасабленне шчаслівага лёсу, поспеху. Лічыцца, што Доля гэта дар багоў, моц, якую далі багі чалавеку для пераадолення жыццёвых цяжкасцей.<br/><br/>
Уяўляецца як нябачная воку прыгожая дзяўчына. Устанаўленне Долі адбываецца ў момант нараджэння і яна суправаджала чалавека да самай смерці, у значнай ступені ўплываючы на яго жыццё, лёс, учынкі і асобу. Доле, як істоце, супрацівапастаўляліся Нядоля і Злыдні - злой лёс, няшчасце.`,
  },
  // {
  //   name: "Дзед Мароз",
  //   src: "Dzed-Maroz",
  //   description:
  //     "Дзед Мароз, Марозны Чараўнік - гэта магутны чараўнік з беларускага гарадскога фальклору, дух-дарыцель, стары ў чырвоным адзенні, які на Каляды і Новы Год дорыць дзецям падарункі, кладзе іх пад ялінку. Цэлы год чараўнік рыхтуецца да свят у сваёй сядзібе ў Белавежскай Пушчы, у нетрах старажытнага Сармацкага лесу, дзе яму дапамагаюць Сьнягурка, сядзібныя духі, красналюдкі, добрыя карузлікі, Снегавікі і лясныя жывёлы. У святочную ноч Дзед Мароз развозіць падарункі на сваіх чароўных санях па ўсёй Беларусі. Цягне яго сані звычайна зубр, часам лось або коні.",
  //   source:
  //     "мастацкія паштоўкі 19 ст., мастацкія паштоўкі СССР, паштовыя маркі, мультыплікація савецкага часу, сучасны гарадскі фальклор",
  // },
  {
    name: "Дзяды",
    src: "Dziady",
    description: `Дзяды - у беларускім фальклоры гэта назва добрых духаў памерлых, а таксама назва старажытнага абраду, свята ў гонар гэтых духаў. Беларусы святкуюць некалькі Дзядоў за год, але галоўнымі лічацца восеньскія.<br/><br/>
Паводле павер'яў, у гэты дзень духі продкаў спяшаюцца ў госці ў дамы жывых да вячэры. Звычайна адно з вокнаў у хаце пакідалі адчыненым, каб Дзяды патрапілі ў хату. Перад святочным вячэрай запальвалася свечкі. Асноўнай рытуальнай стравай Дзядоў лічылася куцця, часам аўсяны кісель і бліны.<br/><br/>
На наступны дзень пасля Дзядоў у некаторых мясцінах Беларусі наступалі Бабы - аналаг Дзядоў, але да куцці прыходзілі ўжо жаночыя постаці продкаў.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Дзяды, А.Міцкевіч 1999; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Ератнікі",
    src: "Eratniki",
    description: `Ератнікі - жывыя мерцвякі, былыя ведзьмары і ведзьмы, што перад смерцю хацелі адрачыся ад сваіх агідных паводзінаў, але не атрымалі дазволу сваіх гаспадароў - Чарцей.<br/><br/>
Штоночы гэтыя пачвары павінны падпільноўваць на дарогах запозненых падарожнікаў і мучыць, нават забіваць людзей у іх дварах ды пабудовах. Ератнік раптоўна абхоплівае ахвяру аберуч ці хапае яе за горла і трымае, пакуль тая не сканае.<br/><br/>
Ератнікаў няшмат, бо ў бойцы яны неміласэрна грызуцца, драпаюцца, душаць адзін аднаго, і пры гэтым ні адзін з іх ніколі не застаецца цэлы. Іх вельмі легка параніць вострымі металёвымі рэчамі і яны хутка гінуць ад уколаў і парэзаў.<br/><br/>
Мёртвы Ератнік ператвараецца ў смуродны пыл, які наносіць шкоду здароўю людзей і жывёл калі трапляе ў арганізм з ежай і пітвом.`,
  },
  {
    name: "Хлеўнік",
    src: "Hleunik",
    description: `Хлеўнік, Хляўнік, Дваровы - сядзібны дух, гаспадар двара, які жыве ў хляве разам з жыўнасцю і мае аблічча маленькага чалавечка.
Жыццё жывёл і крэпасць гаспадаркі залежыць ад Хлеўніка. Калі Хлеўнік любіць сям'ю і скаціну, то у двары і гаспадарцы будзе парадак, калі ён раззлуецца за што-небудзь на гаспадароў хаты, то ён прыносіць шкоду і страты.`,
  },
  {
    name: "Гуменнік",
    src: "Humennik",
    description:
      "Гуменнік - адзін з сядзібных нячысцікаў, які жыве ў гумне - памяшканні для захоўвання і абмолу збожжа. Звычайна выглядае як стары невысокага росту, але можа перавярнуцца ў котку. Гуменнік засцерагае свой дом і ўвогулі хлеб ад усякіх нячысцікаў, бяды і зла, часта спрыяе добраму перамолу. У Гуменніка, як і ў іншых сядзібных духаў, бывае ёсць жонка - Гуменніха.",
  },
  {
    name: "Каляда",
    src: "Kaliady",
    description: `Каляда - асноўнае зімовае свята ў беларусаў, звязанае з пачаткам новага года. Каляда пачынаецца з вечара напярэдадні Раства Хрыстова, як толькі з'яўляецца першая зорка, і доўжыцца аж да самага Вадохрышча.<br/><br/>
У гэты перыяд людзі калядуюць - пераапранаюцца ў розных звяроў (мядзведзя, жураўля, бусла, казу, каня) і ходзяць па хатах з песнямі і пажаданнямі добрага ўраджая ў новым годзе. Каляды гэта перыяд, калі мяжа паміж тагасветным і людскім светам сціраецца, таму ў гэтыя дні адбываецца сапраўдны разгул нячысцікаў, а дзяўчаты пачынаюць варажыць.<br/><br/>
Ведзьмы і чэрці збіраюцца на скокі на балотах. Упадабанай справай ведзьмаў у першую ноч на Каляды з'яўляецца выкраданне зорак і месяца, каб ноч была яшчэ цямней. Беларусы, каб абараніць гаспадарку ад нячысцікаў у гэтую ноч ставілі мелам крыжы на дамы і хлявы.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Кастамахі",
    src: "Kastamahi",
    description:
      "Кастамахі - на Ўсходнім Палессі гэта ажыўшыя нябожчыкі, якія сталі ўжо шкілетамі. Яны часам палохаюць людзей, якія праязджаюць ноччу каля могілак; коні, адыходзяць з храпам, ламаючы аглоблі і іншыя часткі воза, калечаць сябе і людзей, уцякаюць і пачынаюць блукаць. Пазбегнуць гэтага можна, перавярнуўшы наадварот вось воза так, каб колы памяняліся месцамі, каб правае заняло месца левага і наадварот; таксама можна памяняць месцамі і аглоблі. Кожны, хто ўжо сутыкаўся з такой праблемай, робіць так загадзя, калі мяркуецца ехаць міма могілак.",
  },
  {
    name: "Чароўныя Гуслі",
    src: "Husli",
    description:
      "Згодна з беларускім казачным фальклорам, існуюць такія Чароўныя Гуслі, ад гуку якіх кожны чалавек, кожная жывёла і птушка ідзе ў скокі, а на душы становіцца весела і добра. Гусляр, які імі валодае, падарожнічае па ўсім свеце і грае, пакуль сам не састарэе, стане сівым як галава луня, тады ён знясілены перадае гуслі новаму маладому пераемніку.",
    source: "Сіняя світа, У.Сіўчыкаў 2015;",
  },
  {
    name: "Хатнiк",
    src: "Khatnik",
    description:
      "Хатнiк, Падпечнік, Дамавы, Дамавiк - галоўны сядзібны дух, чароўны гаспадар і заступнік хаты, які забяспечвае нармальнае жыццё сям'і, урадлівасць, здароўе людзей, жывёл. Часцей выглядае як стары маленькага роста, але можа прымаць і выгляд гаспадара хаты або коткі.",
  },
  {
    name: "Хохлік",
    src: "Khohlik",
    description:
      "Хохлік - нешта накшталт чарцяня, які любіць совацца па склепах і кладоўках, часам псуе запасы або б’е там посуд. Хохліку таксама прыпісваюць нядобразычлівыя ўздзеянні на каня, на якім ён уночы катаецца і даводзіць яго да знямогі. Для чалавека ён не вельмі варожы, але на раўне з іншымі чарцямі яго можна пабачыць на шабашах на балотах ды Лысых Горах. Можа перакідвацца ў котку.",
  },
  {
    name: "Хут",
    src: "Khut",
    description: `Хут, Клетнік, Фут, Хатні цмок - гэта разнавіднасць хатняга цмока. Чорны певень пражыўшы дзесяць год можа знесці яйка, калі такое яйка вынасіць пад пахай, то з яго вылупіцца Хут. Хут служыць свайму гаспадару і прыносіць яму ноччу ўсё, што трэба.<br/><br/>
Колер цмока залежыць ад дабычы. Калі нясе золата, то ўвесь агністы, калі срэбра - белы, медзь - жоўты, жыта або пшаніцу - цёмны, як хмара. Ён таксама можа легка перакінуцца ў палена, корч ці старое кола, у гэты момант яго можна скрасці і тады ён будзе служыць новай хаце. Любімая Хутава страва - яечня. Калі з’есці Хутаву яечню, то ён раззлуецца і спаліць хату.`,
  },
  {
    name: "Кікімара",
    src: "Kikimora",
    description: `Кікімара або Мара - істота заўсёды жаночага полу, якая можа жыць як на балоце, так i ў жыллi чалавека ды ў іншых гаспадарчых пабудовах. Выглядае як брудная, няскладная, касая ды крывая пачвара з доўгім носам і нават рагамі, але час ад часу можа ператварацца ў чалавека. У Кікімар ператвараюцца пераважна дзяўчыны здзейсніўшыя самагубства, або няхрышчаныя дзеці пасля смерці.<br/><br/>
Кікімара любіць дапамагчы па гаспадарцы ноччу, прасці калаўратам, але выходзіць у яе гэта дрэнна і шкоды атрымліваецца больш, чым дапамогі. Кікімара неварожая чалавеку, але часам надта шкодная, таму лепей каб яна не заводзілася ў хаце.`,
  },
  {
    name: "Копша",
    src: "Kopsha",
    description:
      "Копша - у фальклоры цэнтральнай Беларусі магільны дух у выглядзе жанчыны. Нябожчыкам часцей клалі ў магілу грошы, каб дагадзіць ёй. Функцыі Копшы ад крыніцы да крыніцы розняцца - ад багіні царства памерлых, да абаронцы магільных скарбаў.",
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002;",
  },
  {
    name: "Красналюд",
    src: "Krasnalud",
    description:
      "Красналюд, Красналюдкі — у беларускай і польскай міфалогіі народ маленькіх чалавечкаў. Вядомыя як апекуны жытлаў. Мяркуецца, што антрапалагічна яны паходзяць ад душ продкаў ці памерлых немаўлят. Назва іх звычайна звязваецца з чырвоным колерам галаўных убораў. Удзень хаваюцца пад парогам, за печчу, у мышыных норах, закутках у кузні ці стайні. Уначы, калі людзі кладуцца спаць, выходзяць і да першых петухоў ходзяць навокал, заканчваюць перарваныя працы па доме, сцерагуць дзяцей ад злых духаў.",
    source:
      "Дываны Язэпа Драздовіча, Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Крыкса",
    src: "Kriksa",
    description:
      "Крыкса, Бессань - злы начны дух, які часцей за ўсё нападае на дзяцей ноччу, недае ім спаць, выклікае бяссонніцу, прымушае іх крычаць ды плакаць.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Хіхітун",
    src: "Khihitun",
    description:
      "Хіхітун - у беларускім фальклоры бяскрыўдны дробны нячысцік. Часцей Хіхітун з'яўляецца ў чалавека за спіной, калі з ім адбываецца нейкае няшчасце або здарэнне, дэман пачынае ў гэты момант хіхікаць, смяяцца. Кажуць, калі ў момант смеху рэзка абярнуцца, то можна пабачыць гэтага дэмана. Хіхітун падобны на невялікую малпу з маленькімі рагамі на галаве. Лічыцца, што дэман можа пачаць смяяцца напярэдадні здарэння і калі яго своечасова пачуць, то можна прадухіліць бяду.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Крыжацік",
    src: "Kryzhatsik",
    description:
      "Крыжацік - паводле павер'яў беларускага Палесся, неўпакаенны дух у выглядзе сівога крумкача з чалавечым поглядам, які жыве ў межах могілак. Крыжацікам становіцца нябожчык, чыя магіла, а калі больш дакладна - надмагільны крыж - па якіх-небудзь прычынах былі пашкоджаныя да здзяйснення саракадзённых памінак. Вельмі сурова настроены ў адносінах да вандалаў, можа збіць іх з ног ці заваліць у яму.",
    source: "Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Кумяльган",
    src: "Kumelgan",
    description:
      "Кумяльган - у беларускім фальклоры адмоўная злая істота з тварам і поўсцю каня, а тулавам чалавека. Кумельган у асноўным шкодзіць гаспадарцы тым, што псуе коней, можа іх нават параніць, або пачаставаць якой-небудзь цуда-травой ад якой коні дурэюць, або хварэюць.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Лазнік",
    src: "Laznik",
    description:
      "Лазнік, Лазеннік, Баннік - гэта дух, які жыве ў лазні і з’яўляецца яе гаспадаром. Напэўна, Лазнік самы злосны з усіх сядзібных нячысцікаў. Калі жанчына нараджала ў лазні, то яе баяліся пакідаць там адну з немаўлём, бо Лазнік можа яго забіць або падмяніць. Лазнік не любіць калі ў лазні мыюцца пасля апоўначы, або п’юць ці сварацца, за гэта ён можа напужаць або забіць камнем з печкі. Часцей Лазніка ўяўляюць у выглядзе маленькага, але вельмі моцнага старога з касматай барадой. Калі Лазніка не паклікаць у новую лазню пасля пабудовы, то старую ён можа спаліць або разбурыць.",
  },
  {
    name: "Марня",
    src: "Marnia",
    description:
      "Марня, Мара, Змора - злы дух ці прывід неяснага выгляду, які чымсці падобны на жанчыну з кароткімі, але вялікімі нагамі. Часцей за ўсё Марня чапляецца да людзей ноччу і душыць іх у сне.",
    },
  {
    name: "Меша",
    src: "Mesha",
    description: `Меша - амаль бясшкодны дэман, які жыве ў старых развалінах будынкаў. Знешне гэтыя істоты нагадваюць невялікую чорную кудлатую жывёлу: ні то ката, ні то сабаку.<br/><br/>
Калісьці гэтыя істоты былі злымі балотнымі нячысцікамі, але моцна недагадзілі самому Кадуку, за што апошні пазбавіў іх чароўных здольнасцей.<br/><br/>Часам Меша можа залазіць у жылыя хаты і хавацца пад печ, займаючыся дробным шкодніцтвам. Таму матулі палохаюць сваіх дзяцей, што калі яны не лягуць спаць, то Меша зацягне іх пад печку.`,
  },
  {
    name: "Начніца",
    src: "Nachnitsa",
    description:
      "Начніца - начны дух жаночага полу, які любіць прабірацца ў хату і пужаць малога, каб той не змог заснуць і крычаў ды плакаў усю ноч. У Начніц пераўтвараліся пасля смерці жанчыны, якія ня мелі дзяцей праз шальмоўства ці праклён. Начніцы таксама любяць лазіць па дрэвах, шукаць птушыныя гнёзды, выпіваць яйкі ды з’ядаць птушанят.",
  },
  {
    name: "Нактыр",
    src: "Naktyr",
    description: `Нактыр або Непрытомнік - на поўначы Беларусі гэта лакальныя назвы пачвары накшталт вупыра, нябожчыка, які па начах вылазіць з магілы ды ходзіць да сваіх жывых сваякоў, турбуе іх, шкодзіць хатняй жывёле, нават п’е іх кроў. Непрытомнік наведвае менавіта сваіх сваякоў.<br/><br/>
Беларусы верылі, што ў Накты'раў пасля смерці ператвараліся чарадзеі або вядзьмаркі. Каб Непрытомнік не хадзіў пасля смерці, то пад час пахавання чараўніка, усё рабілі наадварот: памерлага выносілі галавой на перад, на скрыжаванні дарог труну пераварочвалі кругам. Сэнс такіх дзеянняў заблыдаць пачвару, каб яна не знайшла дарогу дадому.<br/><br/>
Ад начных наведванняў памерлай жонкі муж звычайна пазбаўляўся тым, што дыманстратыўна рыхтаваўся да новага шлюба: апранаў святочнае адзенне, пёк каравай і г.д.<br/><br/>
Але ж самы надзейны сродак пазбавіцца ад Непрытомніка - раскапаць у дзень яго магілу, адсекчы галаву і, працяўшы яе асінавым колам, пакласці паміж ног, саму ж магілу пасля пасыпаць макам.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Ох",
    src: "Oh",
    description: `Ох - гэта добры дух дапаможнік. Ох з'яўляецца перад чалавекам у цяжкую, няпростую хвіліну, дастаткова толькі вымавіць: "Ох!...". Выглядае Ох, як стары маленькага росту з барадой да самых ног. Пры дапамозе чараўніцтва і магіі, Ох дапамагае чалавеку, можа начараваць ежу ці нават падарыць які-небудзь магічны артэфакт, да прыкладу, залатую табакерку, якая можа выклікаць духа, які здзяйсняе любыя жаданні.<br/><br/>
Але не кожны чалавек здольны вызваць старога Оха. Такой магчымасцю часцей валодаюць прадстаўнікі рамёстваў, якія маюць блізкае стаўленне да чараўніцтва і звышнатуральных сіл - ганчары, кавалі, пчаляры...`,
    },
  {
    name: "Паляндра",
    src: "Paliandra",
    description: `Паляндра, Мажанна, Маржана - у беларускім фальклоры дух, які варожа настроены да людзей, нават ненавідзіць іх. Паляндру звязваюць з холадам, зімой, смерцю, яе нават называюць багіняй смерці. Калі чалавек памірае, то ў гэты момант у хату да яго можа з'явіцца Паляндра ў выглядзе пачварнай жанчыны ў лахманах і забраць яго душу, не даць ёй ступіць на Гусіную Сцежку.<br/><br/>
Паляндра ў добрым сяброўстве з Ягіняй і іншымі ведзьмамі, менавіта ім яна дастаўляе душы памерлых. Ягіня і іншыя ведзьмы пажыраюць душы, дзякуючы чаму яны становяцца лёгкімі як душа і могуць лётаць на мётлах ды ў жалезных ступах. Адганяюць Паляндру ад памерлага адпаведнымі замовамі ды загаворамі.`,
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Пан",
    src: "Pan",
    description: `Пан, Панейка, Паніч - у беларускай народнай дэманалогіі гэта разнавіднасць Чорта, які прымае аблічча чалавека. Як правіла, Пан з'яўляецца перад чалавекам адзін або ў кампаніі апоўначы ці ў ясную марозную месяцовую ноч. З першай песняй пеўняў Пан знікае.<br/><br/>
Знешне гэта заўсёды багата апрануты чалавек, часам у плашчы і абавязкова ў капелюшы. Калі ўважліва прыгледзецца, то можна ўбачыць хвост, які тырчыць з-пад плашча і рожкі з-пад капялюша. Але часцей людзі гэтых дэталяў не заўважаюць з-за магіі Чорта, толькі спецыяльнае зелле, якім трэба нацерці вочы, дапамагае ўбачыць сапраўднае аблічча нячысціка.<br/><br/>
Пан ніколі не бывае жанчынай, не з'яўляецца ў жаночым адзенні і саміх жанчын ён вельмі не любіць. Лічылася, што з любой сямейнай пары дэман пачне выводзіць спачатку мужа, следам за якім жонка згіне сама па сабе.<br/><br/>
Пан імкнецца збіць чалавека з дакладнай дарогі, угаворамі завесці яго ў лес на балота. Таксама ён любіць уладкоўваць абмен рэчаў, але заўсёды не на карысць чалавека, каб апошні застаўся ні з чым.<br/><br/>
Адвязацца ад Пана можна падманам, да прыкладу, даручыўшы яму невыканальнае заданне: прынесці ваду з ракі ў сіце.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Пасечнік",
    src: "Pasechnik",
    description:
      "Пчаляр, Пасечнік або Пасечны Дух - у беларускім, ўкраінскім і польскім фальклоры сядзібны нячысцік, які жыве каля пчальніка. Сам па сабе гэты дух не з'яўляецца, такога духа трэба заклікаць спецыяльным чынам. Той чалавек, у якога за пчальнікам сочыць і даглядае нячысцік, можа назаўжды забыцца пра якія-небудзь клопаты. Пчальнік сам будзе станавіцца большы і лепшы, пчолы будуць як чмялі, а салодкі мёд будзе цячы ракой.",
    source: "Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Паветніца",
    src: "Pavetnica",
    description:
      "Паветнік або Паветніца – у беларускім фальклоры малодшы сядзібны дух у выглядзе шасцікрылай птушкі. Асноўная функцыя Паветніцы гэта лётаць па ўсёй сядзібе і глядзець не адбылося ці дзе-небудзь бяды. У выпадку, калі па гаспадарцы ёсць нейкія здарэнні, то Паветніца кліча старэйшых духаў - Хлеўніка, Гуменніка ці Хатніка. Асноўнае месца рассялення Паветніцы гэта павець - месца, дзе часцей за ўсё захоўваюць дровы або сена.",
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Павук",
    src: "Pavuk",
    description: `Павукі ў павер'ях іншых народаў гэта часцей істоты цёмныя і адмоўныя. У беларускай народнай традыцыі з вобразам павука звязаны найперш працавітасць і добрыя прадказанні.<br/><br/>
Павук, які спускаецца на стол падчас ежы, прадвяшчае поспех. На Палессі вераць, што чалавек, які забіў павука або парваў павуцінне, будзе затым доўга хварэць на галаву ці з ім станецца няшчасце. Таксама ў нашых продкаў была старажытная традыцыя плесці "Павукоў" з саломы - гэта не толькі важны элемент ўпрыгожвання дома, але і абярэг, артэфакт, які павінен прыносіць поспех.`,
  },
  {
    name: "Пячурнік",
    src: "Piachurnik",
    description:
      "Пячурнік - згодна з беларускім фальклорам, гэта хатні нячысцік, які жыве пад печкай, падобны на котку. Сябра Хатніка, разам з ім ахоўвае хату, дзе сам жыве. У знак пашаны гаспадыня выстаўляла ноччу на печы пачастунак Пячурніку - рэшткі вячэры альбо малако.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Пуннік",
    src: "Punnik",
    description: `Пуннік - у беларусаў сядзібны нячысцік, што жыве ў пуні. Ён уяўляўся чалавекападобнай істотай. Паводле ўяўленняў, звычайна сядзеў на кроквах і назіраў за наведвальнікамі пуні, палохаючы дзяцей і некаторых прахожых хрыпам, свістам ці жаласным енкам.<br /><br />
Пуня, а таксама сянніца, сельніца, адрына або шопа - памяшканне для захоўвання сена.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895;",
  },
  {
    name: "Спорнік",
    src: "Spornik",
    description: `Спорнік або Спарнік - у беларускім фальклоры адзін з сядзібных духаў, захавальнік і памнажальнік багацця гаспадара. Спорнік звычайна набывае постаць чорнага ката і ў гэтым выглядзе яго можна пабачыць у свіране або гумне, дзе гэты кот на таку адрыгвае зерня.<br/><br/>
Калі недасведчаны парабак вырашыць прагнаць такога ката, кінуць у яго што або нават ударыць, то Спорнік спаліць усё гумно, а сам перавернецца ў дугу.<br/><br/>
У той жа час, калі хто здагадаецца перакінуць такую дугу сабе ў двор, то Спорнік будзе жыць у яго і ўсяляк узбагачаць.<br/><br/>
Любімая страва Спорніка гэта несалёная яешня. У гэтым сэнсе Спорнік вельмі падобны да Клетніка (Хута, Хатняга Цмока). Апошні таксама любіць яешню, перакідваецца ў старое кола або палена і носіць аднекуль зерне ў гумно. Функцыі іх вельмі падобныя. (гл. мой малюнак Хут)<br/><br/>
Спорніка і Хута могуць заклікаць толькі ведзьмы і ведзьмары, людзі якія займаюцца чарадзействам.<br/><br/>
Часам Спорнік можа прымаць і выгляд маленькай вогненнай птушкі падобнай да вераб’я.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Ступалака",
    src: "Stupalaka",
    description:
      "Ступалака - чалавек-пярэварацень, які з-за праклёну ператвараецца ў жудасны каменны ці драўляны нерухомы слуп. Гэтая істота толькі мімаходам згадваецца ў Паўла Шпілеўскага, у яго фальклорных даследаваннях аб ваўкалаках. Толькі апоўначы на некалькі гадзін Ступалака можа прыняць чалавечы выгляд.",
    source: "Фольклорное наследие белорусов, П.Шпилевский 2020;",
  },
  {
    name: "Валасень",
    src: "Valasen",
    description: `Валасень - у беларускай міфалогіі гэта злы хатні дух, які па начах шкодзіць і пужае людзей у сне. У сваіх паводзінах ён нагадвае Крыксу, Марню або Начніцу.<br/>
Валасень уяўляецца невялікай пачварнай істотай, парослай густой і доўгай цёмнай поўсцю. Ён таксама мае вялікія далоні і доўгія кіпцюры.<br/>
Мужчынам Валасень казыча насы і драпае твары. Жанчынам і дзяўчатам ён кудлаціць валасы, хапае за грудзі, а то і навальваецца ўсім сваім калматым целам. Малым дзецям ён гудзіць у вушы ці казыча пяткі.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Варгін",
    src: "Vargin",
    description:
      "Варгін, Варган - велізарны дэманічны рэліктавы чорны кот, кароль усіх котак. Гаспадары ў якіх ён паселіцца з часам страчваюць розум і паміраюць, а іх гаспадарка прыходзіць у заняпад.",
  },
  {
    name: "Ваструха",
    src: "Vastruha",
    description: `Ваструха - гэта хатні дух з шэрагу іншых сядзібных нячысцікаў, які выглядае як маленькая бабуля з вялізнымі вушамі. Ваструха засцерагае хату ад злых і зайздросных людзей. Нягледзячы на тое, што Ваструха выглядае вельмі старой, яна мае вельмі востры зрок і неверагодны слых, які пераўзыходзіць слых коткі ці нават кажана.<br/><br/>
Звычайна Ваструха выбірае месца ў хаце вышэй за астатнія: шафа, печ ці лядоўня, каб ёй было ўсё добра відаць і чуваць.<br/><br/>
Злодзеяў і вароў Ваструха можа моцна напалохаць. Любіць яна ганяць і мышэй з катамі, каб не кралі са стала ежу.<br/><br/>
Ваструха ў добрых адносінах і з Хатнікам, і з Пячурнікам, і з іншымі сядзібнымі нячысцікамі. Усе яны ставяцца да яе з павагай, бо яна лічыцца старэйшай за астатніх.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Ваўкалак",
    src: "Vaukalak",
    description: `Ваўкалачыца - у беларускім фальклоры гэта спецыяльная назва для жанчыны-пярэваратня, ваўкалака. Ваўкалакі ў беларускім фальклоры займаюць важнае месца, гэта папулярны персанаж апавяданняў і ён вельмі адрозніваецца ад нямецкага верволфа.<br /><br />
Па-першае, Ваўкалак не мае ніякай сувязі з месяцам, ператварэнне часцей адбываецца ўначы, але поўня тут ні пры чым, таксама здольнасць ператварацца ў пярэваратня не перадаецца праз укус. Гэта адбываецца заўсёды магічным шляхам, у пярэваратняў ператвараюцца па сваёй волі ведзьмакі і вядзьмаркі або праклятыя людзі.<br /><br />
Па-другое, Ваўкалак не заўсёды крыважэрны, небяспечны і вар'ят, што да праклятых пярэваратняў, то наадварот, часцей яны разумныя, трымаюцца бліжэй да свайго старога селішча, крадуць чалавечыя рэшткі ежы і больш пакутуюць самі, чым прычыняюць пакуты. Таксама яны не могуць ператварыцца назад у чалавека, яны ўвесь час у абліччы ваўка і днём і ўначы, у адрозненні ад ведзьмакоў, тыя могуць перакідвацца назад у чалавека.<br /><br />
Па-трэцяе, Ваўкалакі не абавязкова адзіночкі, часта ў апавяданнях у пярэваратняў ператвараюць цэлую групу людзей: родных братоў, гасцей на вяселлі і г.д. Ваўкалак напалову воўк напалову чалавек, хоць часам гэта і проста вялізны воўк, нават чалавечае адзенне схавана пад скурай. Пры парушаным зваротным загаворы, Ваўкалак можа не да канца ператварыцца ў чалавека, можа застацца хвост, воўчыя лапы ці поўсць.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Шляхціц Завальня, Я.Баршчэўскі 1844; Фольклорное наследие белорусов, П.Шпилевский 2020;",
  },
  {
    name: "Вазіла",
    src: "Vazila",
    description: `Вазіла, Канькач, Табуннік, Вісуснік - дух-апякун коней у беларускім фальклоры. Вазіла мае чалавечыя рысы, нават носіць адзенне, але ногі і вушы ў яго конскія. Часцей Вазіла знаходзіцца ў хляве або на стайні, дзе сочыць за здароўем коней, засцерагае ад хвароб, спрыяе роду жарабят. Вазіла падкормлівае коней асаблівай травой, ад якой тыя мацнеюць, становяцца дужымі і рослымі.<br/><br/>
Вазілу зазываюць і на выпас - ставяць тычку з надзетым на яе конскім чэрапам, каб ён аберагаў статак ад драпежнікаў. Але самым галоўным ворагам Вазілы лічыцца Кумяльган.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Ведзьма",
    src: "Witches",
    description: `Ведзьма (муж. Вядзьмар) - часцей за ўсё жаночы персанаж, маладая і незвычайна прыгожая дзяўчына ці страшная старая, якая валодае магічнымі ведамі і здольнасцямі, з дапамогай якіх шкодзіць людзям. Водзіць сяброўства з чарцямі і бесамі, падпарадкоўваецца Ягіне, можа пераўтварацца або пераўтвараць у ваўкалака, насылаць праклёны.<br/><br/>
У беларусаў таксама была вера ў Малочных Ведзьмаў - гэта ведзьмы якія займаюцца крадзяжом малака ці яго псаваннем. Такія ведзьмы падсылаюць у хлеў розных гадаў, каб яны высмоктвалі ў кароў малако, або самі ў іх ператвараюцца, часцей жаб-рапух або змей.<br/><br/>
Таксама ведзьма магла ўсю ноч цягаць хустку або ручнік па роснай траве па чужых межах ды нівах, прамаўляючы магічныя словы, потым вешаць хустку і з яе замест вады сцякала сапраўднае малако ў збанок. Затое ў кароў, якія пасвіліся на гэтых нівах малако знікала.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Шляхціц Завальня, Я.Баршчэўскі 1844; Фольклорное наследие белорусов, П.Шпилевский 2020;",
  },
  {
    name: "Ёўнік",
    src: "Younik",
    description: `Ёўнік, Асетнік - у беларускім фальклоры сядзібны нячысцік, дух, які жыў у ёўні і якому прыпісваліся функцыі аховы локуса і спрыяння гаспадарцы.<br/><br/>
Ёўня (асець, восець, еўня, сушня) - традыцыйная гаспадарчая пабудова для прасушкі збожжавых культур, канаплi і лёну. Будавалася непасрэдна ў гумне або побач з ім. Звычайна мела два ярусы (паверхі): на ніжнім размяшчалася печ-каменка, на верхнім расстаўляліся неабмалочаныя снапы.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфы бацькаўшчыны, У.Васілевіч 1994; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Красная (Чырвоная) Нага",
    src: "Chyrvonaja-Naha",
    description:
      "Красная (Чырвоная) Нага - у беларускім фальклоры вельмі рэдкія істоты, якімі пераважна пужалі малых дзяцей на ўсходніх вёсках каля мястэчка Ветка, што на Палессі. Гэтыя злыя істоты любяць апаноўваць разбураныя будынкі ды апусцелыя вёскі. Лічыцца, што калі Чырвоная Нага дакранецца да чалавека, то яго цела ўсё пачырванее.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Зюзя, Мароз",
    src: "Zuzia",
    description: `Зюзя, Мароз, Ледзяны Дзед або Дзюдзя - у беларускім фальклоры дух зімы, гаспадар і персаніфікацыя маразоў, выглядае як невысокі стары з белай барадой, ходзіць босы ў футры з жалезнай паліцай. На Палессі ўяўлялі як лысага барадатага дзеда, які ў расхрыстаным кажусе, босым хадзіў па снезе.<br/><br/>
Любая жывая істота, якая дакранецца да паліцы Мароза, адразу ж ператварыцца ў ледзяную статую і расчараваць яе будзе амаль што немагчыма.<br/><br/>
Беларусы верылі, што большую частку зімы Зюзя праводзіць у лесе, але часам наведвае вёскі, прыносіць туды моцную сцюжу. Раззлаваны, Зюзя б’е булавой па пні, і тады пачынаецца траскучы мароз.<br/><br/>
Любімая справа Зюзі гэта біць сваёй паліцай па дрэвах і падмарожваць іх. Часам Мароз можа нават сесці на дах хаты, ад чаго тая пачынае моцна трашчаць.<br/><br/>
Увогуле, Зюзя не злы дух, але беларусы на Каляды ў Шчодры вечар звычайна кідаюць яму лыжку куцці ў кут, каб яго задобрыць.`,
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Чароўны Свет, У.Васілевіч, В.Славук 2012; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Міфы бацькаўшчыны, У.Васілевіч 1994;",
  },
  {
    name: "Другасысы",
    src: "Drugasysy",
    description: `Другасысы - з першага выгляду, гэта звычайныя людзі, але ёсць у іх і нешта ад нячысцікаў, інакш не трапілі бы яны ў мой нататнік.
<br/><br/>Чалавек, якога ў маленстве маці двойчы пачынала карміць грудзямі, як правіла, у выніку атрымліваў сурочлівыя вочы - ён мог ненарокам наслаць на каго-небудзь Сурокі або Прыстрэкі.
<br/><br/>Вясковыя такіх людзей называюць Другасысы, ім нельга глядзець на чалавека падчас працы, на цяжарных жанчын, на жывёлу, асабліва пад час ацёлу, акоту і інш. Калі гэта адбываецца, у першым выпадку праца аказваецца марнай, у другім - нараджаюцца калекі.
<br/><br/>Канешне ж, у выпадку, калі аднавяскоўцы даведваюцца пра такую чароўную сілу ў чалавека, то ён адразу стане ізгоем і кожны будзе ад яго калітку запіраць.`,
    source: "?",
  },
  {
    name: "Сярбай",
    src: "Sjarbai",
    description: `"Сярбай — дух голаду. Істота вельмі рэдкая і сустракаецца пераважна на паўночным захадзе беларускіх земляў. Пра яго вядома вельмі няшмат. Менавіта таму я неадкладна рушыў у дарогу, атрымаўшы ліст ад майго гродзенскага знаёмага Трафіма Багушэвіча, які апошнія некалькі год жыў у невялікім мястэчку каля Браслава, дзе працаваў адвакатам і займаўся абаронай правоў сялян.
<br/><br/>Ужо як месяц у яго сядзібнай прыбудове жыве жудасная істота, падобная па апісанні на Сярбая. Па словах Трафіма, яна бяспечная і ні на каго не нападае, проста цэлымі днямі сядзіць у прыбудове. Але ён адчувае, як знаходжанне духу згубна ўплывае на жыццё сядзібы і на яго справы ў цэлым. Апошнія некалькі месяцаў Трафім ледзьве зводзіць канцы з канцамі. Грошай пачало нехапаць нават на простую ежу.
<br/><br/>Прыбыўшы на месца я пацвердзіў свае асцярогі. Худая чалавекападобная істота, замест вопраткі безліч зблытаных ірваных пустых мяшкоў і торбаў, а на твары маска з белай скуры з чырвонымі вуснамі і румянамі на шчоках.
<br/><br/>Нажаль, я зусім не ведаў, як прагнаць гэтага духа. Усе мае спробы сканчаліся тым, што дух пачынаў крычаць і шумець, брынчучы мяшалкай, на якой быў перавернуты саган.
<br/><br/>Трафім нікому пра гэта не расказваў і як аказалася дарма. Пагаварыўшы з мясцовымі старажыламі я даведаўся, што тут ледзь не кожны ведае пра Сярбая і як яго "зарэзаць". Трэба ўсяго толькі прынесці ў дом, дзе пасяліўся Сярбай, першы зажаты сноп. Духі голаду якраз звычайна любяць з'яўляцца ў паселішчах у канцы зімы - пачатку вясны, калі запасы правізіі ў сялян амаль сякуць.
<br/><br/>Мы прагналі духа — ён уцёк у лес з жахлівымі стогнамі. Справы ў Трафіма пайшлі ў гару. А мой спіс зброі быў пашыраны яшчэ адным абрадам."
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "?",
  },
  {
    name: "Камар",
    src: "Kamar",
    description: `"Часам, людзі якія не бачылі і не вераць у нячысцікаў пытаюць мяне: "Ян, а ці не звар’яцеў ты? Дзе ж яны, нячысцікі? Пакажы!" Я заўжды такім адказваю, што і яны іх таксама бачылі і добра ведаюць. Вось узяць звычайнага камара - гэта той яшчэ нячысцік, і кроў п'е, і чалавеку з жывёлай зло робіць. Паводле старой беларускай легенды, калі Бог стварыў пчол, то Чорт яму пазайздросціў і вырашыў зрабіць сваіх. Плюнуў ён у дупло, а адтуль на яго шэршні ды восы паляцелі. Чорт пачаў іх секчы кіпцюрамі, а тыя на камароў, мошак і іншы гнюс распаліся. Так гэтыя чортавы крывапіўцы і дагэтуль у людзей кроў п'юць…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "?",
  },
  {
    name: "Кука",
    src: "Kuka",
    description: `Кука - нячысцік, якім палохаюць дзяцей, якія адмаўляюцца класціся спаць ноччу. Кука мае жаночую прыроду, жыве ў цемры і сама чорнага колеру. Мала каму ўдалося разгледзець дэталі яе знешнасці, але звычайна людзі адзначаюць такія рысы як крывізна, сагнутасць, вострагаловасць.
<br/><br/>Сустракаецца паўсюдна ад вёсак каля Дзятлава, да мястэчак Драгічынскага Палесся`,
    source: "",
  },
  {
    name: "Мышка і зуб",
    src: "Myshka",
    description: `“Алесь падбег да печкі, абнёс свой маленькі выпалы зуб вакол галавы тры разы і кінуў на печ са словамі: “Табе мышка ліпавыя зубы, а мне касцяныя!”. Потым хлопчык вярнуўся ў пакой і заскочыў у ложак накрыўшыся коўдрай. Я сядзеў недалёка за сталом і ў святле свечак дапісваў свой дзённік.
<br/><br/>— Дзядзька Ян! А што, мышка і сапраўды мой зуб забярэ?
<br/>— Вядома!
<br/>— А чаму мы кладзем зуб менавіта мышцы? Чаму не Хатніку ці Красналюдкам?
<br/>— У мышэй наймацнейшыя зубы. Таму менавіта іх і просім, каб яны гэтай моцай падзяліліся. Малочны зуб дзіцяці мае свае асабістыя магічныя ўласцівасці. Ад таго і ведзьмы за ім палююць, і іншыя нячысцікі. Лепей яго не пакідаць сабе, каб яны яго не забралі. Некаторыя гарэзныя пачваркі не супраць ім паласавацца. Мышкі ўдзячныя за такі падарунак. Некаторыя лічаць, што ў абліччы мышэй за зубам прыходзяць нашыя продкі.
<br/>— А куды мышкі выносяць зуб? Што з ім яны робяць?
<br/>— Думаю, свайму каралю Паднору нясуць. А той ужо сваім вядзьмарствам дзіцяці моцных зубоў даруе.
<br/>— А ты ніколі не спрабаваў прасачыць і даведацца?
<br/>— Не, перспектыва сустрэцца з Паднорам мяне не вельмі радуе. Ды і мышкі спецыяльна ж падаруначак пакідаюць, каб людзі іх не турбавалі.
<br/>— Падарунак? Хм... мне ніколі не пакідалі.
<br/>— Так? Ну, можа на гэты раз. Зачыняй вочы, Алесь...
<br/><br/>Я скончыў свае запісы і пагасіў свечкі. Хлопчык ужо моцна спаў. Я ўстаў і падышоў да печы, дастаў манету і хацеў быў пакласці яе замест зуба, але выявіў, што зуба няма! А замест яго ляжала пяць залатых рублёў...Дзіўна…”
<br/><br/>З натакаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
  {
    name: "Ляк або Пярэпалах",
    src: "Piarepalah",
    description: `“Раней малы нават і неведаў, на колькі вясковыя шаптухі магутныя постаці і якой барацьбе аддаюць сваё жыцце.
<br/><br/>У Стайках неяк старая нянька Данута вадзіла Уршулю да шаптухі, тая загаворвала хлеб, каб прайшло заіканне. Я тады на гэта ўсё глядзеў не бачучы сэнсу. Тая шаптуха вельмі набажная была, кожную нядзелю да касцёла хадзіла.
<br/><br/>Учора я быў у Расонах і мне пашчасціла пабачыць сакральны момант загавора чалавека ад пярэпалаха. З кавалачкам вужынай кароны, зараз я маю здольнасць бачыць тых, каго выганяе шэпт…”
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
  {
    name: "Курнэля",
    src: "Kurnelja",
    description: `"Пятро сядзеў на табурэтцы з разбітай галавою. Я, усміхаючыся, акуратна забінтоўваў яго ранкі. У маёй сумцы, акрамя зелляў Феліцыя, заўсёды аптэчныя прэпараты першай неабходнасці, у тым ліку і бінты.
<br/><br/>Дзякуй, што я быў тут недалёка ў Жырмунах. Пятро пачуўшы шум на вуліцы, выйшаў разабрацца з надакучлівай птушкай, якая лётала па двары і паднімала пыл слупам так, што ўсе сабакі навокал брахалі.
<br/><br/>Хлопец толькі замахнуўся на яе граблямі, як тут жа на яго наляцела з дзесяціну такіх птушак.
<br/><br/>Курнэля і сапраўды падобная знешне на сароку, але мае хвост лісы. Толькі ў адрозненні ад лісінага, гэты як палена цьвёрды і цяжкі. Гэтыя нячысцікі часам з'яўляюцца ноччу ў дварах і наводзяць шляхетны шум. Пакуль яны пануюць, на вуліцу лепш не выходзіць.
<br/><br/>Пятро моцна быў збіты гэтымі духамі. Але, блага, застаўся жывы…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
];

const water = [
  {
    name: "Азярніца",
    src: "Aziarnitza",
    description:
      "Азярніца - у беларускім фальклоры азёрны від Русалак. Дакладна вядома, што яны жывуць у Чорным Возеры, што ў Мядзельскім районе. Возера тое чорнае-чорнае, бо вельмі глыбокае, але вада ў ім чыстая. У азярніц  доўгія зеленаватыя валасы, цёмная скура, ступні ў выглядзе плаўнікоў. I хоць знешне істоты нагадваюць людзей, замест крыві ў іх нібыта вада, і на дотык яны вельмі халодныя. Размаўляюць азярніцы  на незразумелай, як бы птушынай мове. Калі раптам які чалавек убачыць азярніц , ён не павінен сябе выдаць, бо азярніцы  зацягнуць яго да сябе, на самае дно, і ўжо не адпусцяць. Гэта ж чакае і таго, хто асмеліцца выкупацца ў гэтым возеры.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Баламуцень",
    src: "Balamutsen",
    description:
      "Баламуцень, Баламутнік - паводле беларускага фальклора, небяспечны для жанчын вадзяны чалавек з азызлым тварам, гусінай скурай і велізарным жыватом. Прыстае да дзяўчат, выкрадае іх, але заўседы адпускае.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Хабёр",
    src: "Haber",
    description:
      "Хабёр — паводле беларукага фальклору, цар або кароль усіх ракаў, які вылучаўся сярод сваіх падпарадкаваных тым, што меў залатыя клюшні і вочы, быў большых памераў чым астатнiя.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Казытуха",
    src: "Kazytka",
    description:
      "Ласкатуха, Казытуха, Казытка - у беларускім фальклоры разнавіднасць русалак, якія з гарэзнасці могуць заказытаць маладога хлопца ці дзяўчыну да смерці. Знешне выглядаюць як маладыя прыгожыя дзяўчыны ў белай як снег вопратцы. Часцей іх можна сустрэць у лесе, у полі або каля вадаёмаў.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Беларускі фальклор. Выпуск 3, 2016;",
  },
  {
    name: "Лепельскі цмок",
    src: "Lepel-Dragon",
    description:
      "Лепельскі цмок - азёрны цмок, разнавiднасць беларускага Цмока, якi жыў у Сенненскіх азёрах і Лепельскім возеры. Цела яго было падобна на цела звера фока, з плаўнікамі, доўгай шыяй, а морда адначасова падобная і на галаву змяі і на галаву лані. Верагодна гэта адзін з самых знакамітых беларускіх цмокаў. Узгадваецца ў аповесці У. Караткевіча «Хрыстос прызямліўся ў Гародні».",
    source:
      "Хрыстос прызямліўся ў Гародні, У.Караткевіч 1972; Беларускі фальклор. Выпуск 8, 2021;",
  },
  {
    name: "Лукомскі цмок",
    src: "Lukoml-Dragon",
    description: `Лукомскі цмок - міфічны Беларускі цмок, які жыў у лясах каля Лукомскага возера. Легенда кажа, што ён мог прымаць чалавечы выгляд і шыць адзенне для мясцовых жыхароў за грошы. Адметнай рысай цмока было тое, што ён мог пераўтварацца ў вясёлку і смактаць у гэтым выглядзе ваду з возера.<br/><br/>
Цмока загубіла неўзаемнае каханне да мясцовай дзяўчыны, яго забіла маланка. Усё што зараз нагадвае пра тую легенду, гэта амшалы валун каля возера. Ці гэта рэшткі цмока, ці гэта было яго жытло, ужо не вядома.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Беларускі фальклор. Выпуск 8, 2021;",
  },
  {
    name: "Малімон",
    src: "Malimon",
    description:
      "Малімон - у беларускай міфалогіі гэта неверагодна велізарная істота падобная на рыбу, якая жыве ў падземных водах. На яе спіне стаіць увесь Палесскі край. Калі Малімон  усплывае, тады рэкі выходзяць з берагоў, а калі апускаецца, бываюць моцныя засухі. Прабраўшыся праз цемру Палесскіх падземных тунеляў, на самым беразе падземнага цёмнага Беларускага мора, можна пабачыць твар Малімона...",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Русалка",
    src: "Rusalka",
    description: `Русалка, Русаўка, Купалка - у беларускім фальклоры нежыць жаночага полу, адмоўны дух. Не блытаць з Марской Каралеўнай, якую апісваў у сваіх казках Г. Х. Андэрсэн. Русалкамі станавіліся памерлыя без хросту дзеці, дзяўчаты, якія не паспелі выйсці замуж, або тапельніцы. У беларускім фальклоры неверагодна шмат розных відаў Русалак і ўсе яны могуць мець розны выгляд.<br/><br/>
      Любімая справа Русалак гэта завабліваць юнакоў да вадаёмаў і тапіць іх. Нярэдка сустракаюцца Русалкі-пераваратні, якія могуць ператварацца ў розных лясных звяроў, а таксама птушак. Часам у беларускім фальклоры згадваюць, што ў Русалак можа быць жалезны хвост, жалезныя пальцы або грудзі. Таксама ёсць запісы і пра крылатых Русалак.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Беларускі фальклор. Выпуск 3, 2016; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Міфалогія роднага краю, А.Ненадавец 2018; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Русалка (Русальны тыдзень)",
    src: "Rusalka2",
    description: `Магічным шляхам жалезны хвост ператвараецца ў ногі, калі яны выходзяць на сушу, бегаюць па лесе, або гойдаюцца на ветках дрэў пад час Русальнага тыдня.<br/><br/>
Русалкі могуць мець русыя, жоўтыя, чорныя, зялёныя і нават рудыя валасы, але яны заўсёды распушчаныя і доўгія.`,
  },
  {
    name: "Рыбін Кароль",
    src: "Ryby-Karol",
    description: `Рыбін Кароль, Рыбін Цар, Цар Рыб - велізарная містычная істота знешне якая нагадвае рыбу. У беларускім фальклоры гэта ўладар усіх водных істот: рыб, трытонаў, Русалак і Вадзянікаў. Выключэннем з'яўляюцца толькі рачныя ракі, якія маюць свайго ўласнага караля – Хабёра.<br/><br/>
Рыбін Кароль любіць пераганяць зграі рыб з адной ракі ў іншую. Там дзе ён з'яўляецца, ніводны рыбак не зможа злавіць нават самую маленькую рыбку. У караля ёсць зіхатлівая прыродная карона, як і ў іншых містычных каралёў - Паднора ці Цара Вужоў.<br/><br/>
Смяротнаму чалавеку практычна немагчыма сустрэць Караля Рыб, але пра яго любяць расказваць Русалкі і Вадзянікі млынарам, адчыняючы ім таямнічыя скарбы, якія час ад часу Кароль Рыб хавае на дне рэк.`,
    source:
      "Зачараваны замак, У.Сіўчыкаў 2016; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Свіцязянка",
    src: "Svitsiazianka",
    description:
      "Свіцязянка, Сьвіцязянка - разнавіднасць лакальных Русалак, якія жывуць у возеры Свіцязь. Паводле легенды, на месцы возера быў горад, які затануў пры варожай аблозе. Магічным шляхам жыхары ператварыліся ў водных істот, духаў помсты. Легенда стала знакамітай праз творы не менш знакамітага на ўвесь свет беларускага і польскага пісьменніка Адама Міцкевіча.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Свіцязянка: Вершы, балады, паэмы, А. Міцкевіч 1996; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Топелец",
    src: "Topelec",
    description:
      "Утопец, Утопца, Топелец - ва ўсходне славянскім фальклоры злая вадзяная нежыць, якой становіцца памерлы ў вадзе злачынец ці грэшнік, альбо патоплены няхрышчаны.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Вадзянік",
    src: "Vadzianik",
    description:
      "Вадзяны, Вадзянік, Вадзяны дзед - у беларусаў водны дух, гаспадар вод, ўвасабленне стыхіі вады, як адмоўнай з'явы. Можа негатыўна быць настроены да чалавека, утапіць яго. Жыве ў глыбокіх вадаёмах, калодзежах, вельмі любіць вадзяныя млыны.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Ядзеркі",
    src: "Yadzerki",
    description: `Ядзеркі, Яваркі, Марскія Каралеўны - паўлюдзі-паўрыбы. Згодна з беларускім фальклорам яны жывуць у моры, кіруюць імі тры сястры - Марскія Каралевы незвычайнай прыгажосці. Апроч людзей з рыбінымі хвастамі іх каралеўства насяляюць іншыя фантастычныя марскія істоты, якія ахоўваюць іх валадарствы.<br/><br/>
Ядзерак яшчэ называюць Фараонкі, паводле легенды яны пайшлі ад войска егіпецкага фараона, якое гналася за Майсеем і было паглынута морам, пасля чаго людзі і іх коні адгадавалі сабе рыбія хвасты.<br/><br/>
Кажуць Нясвіжскі князь Пані Каханку меў стасункі з адной з Фараонак у сваім плаванні, таму на застоллях заўсёды прыглядаўся да страў з рыбы, каб пераканацца, ці гэта яго дзеткі прыгатаваны і пададзены да стала, а ці не.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Жэўжык",
    src: "Zheuzhyk",
    description: `Жэўжык, Жувжа - у беларускім фальклоры старажытны напаўбог, які пануе на рэках. Істота настолькі магутная, што яго баяцца і яму падпарадкоўваюцца іншыя водныя духі - Лазавік і Вадзянік.<br/><br/>
Жэўжык добразычліва настроены ў адносінах да людзей, можа выратаваць тапельца або выцягнуць цэлую лодку. Знешне гэта істота нагадвала старога з рудай барадой, доўгай шыяй і доўгімі тонкімі рукамі. Днём Жэўжык плавае на самым дне ракі і толькі ўначы выходзіць на паверхню, для людзей ён часцей нябачны. Жэўжык неверагодна моцны і хуткі.`,
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Беражніцы",
    src: "Berazhnitsy",
    description: `Беражніцы - адна з назваў лакальных Русалак. Шмат іх жыве ў возеры Нарач.
<br/><br/>Ёсць старая легенда, паводле якой, калісьці напоўначы Беларусі жыла вельмі прыгожая дзяўчына, але круглая сірата, якую клікалі Нара.
<br/><br/>Нара пакахала мясцовага рыбака Ача. Але злы мясцовы пан, якому таксама падабалася дзяўчына, перашкодзіў іх каханню і аддаў юнака ў жаўнеры. На развітанне Ач падарыў каханай чароўныя блакітныя пацеркі. Кажуць, ён атрымаў іх ад магутнай вядзьмаркі.
<br/><br/>Як толькі пан не зманьваў Нару, у выніку сілай яе загадаў прывесці да яго. Панскія служкі, гвалтоўна хапаючы Нару, парвалі чароўныя пацеркі на яе шыі. Пацеркі пакаціліся па зямлі скрозь лясы і ператварыліся ў азёры.
<br/><br/>Нара не аддала на здзек сваю дзявочую годнасць і кінулася ў самае вялікае возера.
<br/><br/>Кажуць, потым у гэтым возеры з’явілася нейкае рагатае страшыдла, і з навакольных вёсак пачалі знікаць маладыя дзяўчаты. Пойдзе красуня купацца - дамоў не вяртаецца. Прыблуда забіраў іх сабе на забаву. Толькі ў Купальскую ноч ён адпускаў паланянак. Беражніцы выходзілі на бераг возера і вадзілі карагоды, спявалі самотныя песні.
<br/><br/>Праз пэўны час Ач вярнуўся дахаты. Даведаўшыся пра Нару, ён доўга блукаў вакол возера. Нарэшце ён сустрэў Беражніц. Заварожаны іхнімі спевамі Ач забыўся пра каханую, але запомніў самотлівыя песні Русалак. Вандруючы ад вёскі да вёскі, ён разносіў іх паўсюль.
<br/><br/>З цягам часу тое вялікае возера атрымала назву Нарач ад імёнаў хлопца і дзяўчыны...`,
    source: "?",
  },
  {
    name: "Чараўнік Рымар",
    src: "Charaunik-Rymar",
    description: `Нарэшце я трапіў у Рымаўшчыну - гэта чароўнае мястэчка каля Стоўпцаў, і нарэшце я пабачыў гэты вялізны валун пасярод вёскі, пра які ходзяць неверагодныя легенды.
<br/><br/>Кажуць, што назва месца пайшла ад імя чараўніка Рымара. Раней тут не было паселішча, толькі вялізнае возера, побач з якім і жыў адзін з самых магутных чараўнікоў беларускіх зямель - Рымар. Ператварыць чалавека ў камень або ваўкалака для яго было звычайнай справай. Каля возера ён пабудаваў сабе хату, кузню і млын. Была ў яго і сям’я, дзеці - хлопчык і дзяўчынка.
<br/><br/>Возера, ля якога жыў чараўнік, было не звычайнае. Крыніца, з якой яно паходзіла, была акенцам у іншы містычны свет. Пад ёю былі глыбокія лёхі, якія вялі да віроў буйных рэк, да дзікіх азёр і гэтакіх жа бяздонных крыніц-акон. Калі каза, карова або чалавек падыходзілі да гэтага возера, адтуль высоўвалася аграмадная лапа невядомай пачвары і хапала небараку.
<br/><br/>Аднойчы, дзеці Рымара гулялі каля возера і падыйшлі да яго вельмі блізка. Возера іх забрала, яны згінулі. Звар’яцеўшы ад злобы і жадаючы помсты, Рымар вырашыў знішчыць усё жывое і чароўнае ў возеры. Здзейсніўшы гэта ён перакінуўся ў вялізны валун і накрыў крыніцу-акенца, пасля чаго возера высахла, а праход у іншы свет знік.
<br/><br/>Людзі пабудавалі тут хаты і пасяліліся. Шмат хто з іншых чараўнікоў прыходзіў да іх і прапанаваў знішчыць валун, адчыніць праход туды, дзе шмат неверагодных скарбаў і магічных артэфактаў. Але тутэйшыя заўсёды адмаўлялі, шкадуючы свае хаты, якія былі б тады затопленыя.
<br/><br/>Што за жудасныя пачвары раней там жылі? Хто гэтыя нячысцікі? Адкуль яны прыйшлі? Напэўна, толькі валун гэта ведае. Я стаю побач з ім, але ён толькі маўчыць...`,
    source: "?",
  },
  {
    name: "Дух Фарон і Жабер-трава",
    src: "Faron-i-Zhaber-trava",
    description: `Фарон - паводле беларускіх павер’яў гэта злы вадзяны дух, які насылае на людзей страшныя хваробы ды праклёны, ды і ўвогуле чыніць зло. У Фарона ёсць 7 братоў, Нікітрон - злы дух агню, а таксама Ярон, Ірон, Фаразон, Кітрон, Лідон і Сталідон, якія адпавядаюць розным стыхіям.
<br/><br/>Магутныя Чарнакніжнікі з дапамогай пэўнага чараўніцтва могуць вызываць гэтых злых духаў сабе на службу.
<br/><br/>Паводле паданняў беларускіх цыган, дух Фарон селіцца выключна ў тых азёрах, дзе на дне расце чароўная Жабер-трава. Гэта расліна ззяе, як зорка, у глыбіні возера, на самым дне сярод вострых камянёў. Лічыцца, што гэтая трава засцерагае жытло ад пажараў, а таксама можа паказаць чалавеку будучыню, калі нават адзіным яе лістом дакрануцца да ілба. З-за чароўных якасцей шмат хто з людзей жадае яе знайсці.
<br/><br/>Фарон гадзінамі можа сядзець на дне возера і глядзець на чароўную расліну, ён дбайна ахоўвае траву ад іншых вачэй і рук, а калі хто-небудзь наважыцца скрасці расліну, то ад гнева шкоднага духа будзе не ўцячы.`,
    source: "?",
  },
  {
    name: "Русалім",
    src: "Rusalim",
    description: `У Беларускім фальклоры Русалкі адныя з самых распаўсюджаных персанажаў, апісана шмат варыяцый, тыпаў і відаў гэтых міфічных істот. Некаторыя кажуць, што сярод іх толькі жанчыны. Але гэта таму, што істоты мужчынскага полу вельмі рэдкія. У іх нават ёсць свая назва - Русалімы.
<br/><br/>"Як дзівкі - цыцкі каменны, імі можэ чоловека убіці; Русалімы - як обыкновенно мужчіна" 
в.Дзякавічы, Жыткавіцкі р-н, Гомельская вобл.
<br/><br/>Невядома, мо на Русальным тыдне мала мужыкоў памірае, а мо Русалімы больш асцярожныя ў стасунках з жывымі, чым Русалкі, але што ёсць, то ёсць, людзі бачылі іх вельмі рэдка.
<br/><br/>"Много йіх, усякіе, і старые, і молодые, і дзеці, і мужыкі, і бабы. Гэто хто на Русальны тыдзень помрэ, то Русалкою становіцца" 
аг.Сінкевічы, Лунінецкі р-н, Брэсцкая вобл.`,
    source: "?",
  },
  {
    name: "Вадзяны бык",
    src: "Vadziamy-Byk",
    description: `Вадзяны бык - істота вельмі рэдкая на беларускіх землях. Прынамсі, я ведаю толькі адно возера, дзе яна жыве. Гэта недалёка ад мястэчка Ушачы. Мясцовыя вельмі паважана ставяцца да быка і дагэтуль носяць яму ахвяры.
<br/><br/>Вадзяны бык - гэта дух возера, гаспадар прасторы, ён пануе возерам і галоўны над усім, што ў ім жыве. Гэтым ён падобны да Вадзяніка, але ў адным возеры не можа жыць і Вадзянік, і Вадзяны бык.
<br/><br/>Звычайна ранкам і ўвечары можна каля возера пачуць вой быка. Трэба быць асцярожным з духам, бо ён можа і ўтапіць чалавека. Улетку, Вадзяны бык часам выходзіць з вады і можа нават забіваць кароў і быкоў, што пасвяцца каля вады.
<br/><br/>Кажуць, што на захадзе шмат такіх духаў і называюць іх Келпі, а вось Вадзянікаў там наадварот - вельмі мала.`,
    source: "?",
  },
  {
    name: "Вадзяніца",
    src: "Vadzianica",
    description: `Вадзяніца, Рачная Дзеўка - у беларускім фальклоры разнавіднасць Русалак, якая сустракаецца на Мядзельшчыне. Душы тапельніц паўстаюць у вобразе маладых дзяўчат з доўгімі распушчанымі валасамі, апранутых ў празрыстыя белыя сукенкі да зямлі.
<br/><br/>Час ад часу Вадзяніцы выходзяць з вады, апранаюць вяночкі з белых кветак і водзяць на беразе ракі карагоды. Рухаючыся павольна і плаўна, яны спяваюць сумныя і працяглыя песні.
<br/><br/>Рачныя Дзеўкі вельмі небяспечныя для чалавека. Той, хто  іх пабачыць і падыдзе вельмі блізка, абавязкова трапіць у карагод і будзе зачарованы. Пасля, Вадзяніцы заманяць ахвяру ў раку, у самы вір, дзе яна і згіне.`,
    source: "?",
  },
  {
    name: "Свіслацкая Русалка",
    src: "Svislatskaja-Rusalka",
    description: `Паміж вуліцай Францысканскай і Падгорнай у Менску стаіць невялікі шляхецкі дом, у якім раней жыла вельмі прыгожая паненка. Была яна, праўда, шалапутнай і вельмі разбэшчанай. Любіла дурыць галовы маладым шляхціцам, якія да яе заляцаліся, а такіх гавораць было шмат.
<br/><br/>Адзін малады і не надта багаты шляхціц ужо вельмі доўгі час быў закаханы ў паненку. Тая добра гэта ведала і гуляла з ім, як котка з дурненькім мышаняткам. Аднойчы яна пажадала, каб ён прынёс ёй самыя прыгожыя кветкі ў свеце, якія будуць вартыя яе прыгажосці. Калі шляхціц выканае загад, то паненка жэніцца на ім. Паненка намякнула, што кветкі гэтыя растуць у парку побач з Нясвіжскім замкам.
<br/><br/>Шляхціц адправіўся ва ўладанні Радзівіла і змог у таго выпрасіць запаветны букет кветак. Аднак, вярнуўшыся ў Менск, шляхціц знайшоў паненку ў абдымках іншага. Ён ашалеў ад злосці.
<br/><br/>Звар’яцеўшы шляхціц рассек шабляй свайго суперніка, схапіў паненку і выбег у двор, дзе бушаваў дождж. Ён дабег да Ляхавіцкага маста і ў гэты момант да юнака вярнуўся розум. Але раптам маланка ўдарыла ў тое месца, дзе ён спыніўся і абодва паляцелі ўніз, ў раку Свіслач.
<br/><br/>Менавіта пасля гэтай гісторыі ў тым самым месцы ракі час ад часу пачалі знікаць мужчыны. Гавораць, гэта Свіслацкая Русалка ў вясельнай сукенцы топіць прыгожых юнакоў. Часам побач з гэтай Русалкай могуць убачыць і Вадзяніка...`,
    source: "?",
  },
  {
    name: "Пячорны цмок",
    src: "Piachorny-Dragon",
    description: `Пячорны цмок - адзін з самых рэдкіх відаў цмокаў на Беларусі, бо наш ландшафт не вельмі спрыяльны да з'яўлення пячор. Але згодна з фальклорнымі нататкамі, дзе-нідзе раней у нашым краі былі і пячоры.<br/><br/>
Так, вядома, што каля Мастоў на Гарадзеншчыне ў пячоры раней жыў пустэльнік. У Вяляцічах, што каля Барысава, у пячоры жылі два манахі, а паміж вёскай Сялібка і Ясны Лес, што каля Бабруйска, пячору, як сваё логава, выкарыстоўвалі 13 паўстанцаў, што рабавалі мясцовых паноў. Вядомыя і іншыя пячоры.<br/><br/>
Пячоры прываблівалі адпаведны від цмокаў не толькі сваёй утульнасцю, але і скарбамі, якія ў іх часам хавалі людзі. Адна з самых вядомых беларускіх пячор, якая захавалася і дагэтуль, знаходзіцца каля возера Гінькава, што ў Глыбоцкім раёне. У народзе яе заўсёды клікалі "Цмокава пячора". Па легендзе, раней у пячоры жыў цмок, які абараняў скарб з золатам. Куды знік цмок? Што здарылася са скарбам? Не вядома.`,
    source: "мясцовая легенда",
  },
  {
    name: "Расамаха",
    src: "Rasamaka",
    description:
        'Расамаха, Расамака - злоснае фантастычная істота ("звер-жанчына") у беларускім фальклоры, жыла ў жытнім або канапляным поле; жанчына са звярынай галавой і лапамі, звычайна з доўгімі пальцамі.',
    source:
        "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Заклятыя скарбы, У.Ягоўдзік, А.Навіцкі 1992; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Смалянка",
    src: "Smalianka",
    description:
        "Смалянка - у беларускім фальклоры гэта разнавіднасць Русалак, якія жылі на Магілёўшчыне ў Гарэцкім раёне. Часцей  іх можна было пабачыць у сажалках каля рэк. Смалянкамі звычайна пужалі малых дзяцей, каб яны не лезлі да ракі без дарослых. Знешне гэтыя істоты выглядалі, як вялізныя пацукі з жаночым тварам, жоўтымі вачамі і доўгімі валасамі.",
    source: "Беларускі фальклор. Выпуск 3, 2016;",
  },
  {
    name: "Мышанка",
    src: "Myshanka",
    description: `“Натхнёны сваімі прыгодамі на возеры Свіцязь, я вырашыў паспрабаваць свой поспех і на рацэ Мышанка. Калі Адам Бернард не выдумаў сваю русалку, то магчыма і іншы мой зямляк Ян Чачот таксама.
<br/><br/>Згодна з тэкстам балады, рачная панна з'яўляецца на беразе менавіта вясной перад Юр'евым днём. Я напісаў ліст мясцоваму ксяндзу Стэфану Калянкевічу і атрымаў запрашэнне пагасціць у мястэчка Новая Мыш.
<br/><br/>Па легендзе, у гэтых месцах раней жыў чалавек з прозвішчам Мышка. У яго была неапісальнай прыгажосці дачка. Аднойчы яна адправілася ў лес непадалёк збіраць арэхі. У лесе адбылася нечаканая сустрэча з рыцарам са шляхетнага роду, які там паляваў. Угледзеўшы простую дзеўчыну дваранін пачаў да яе прыставаць і дамагацца.
<br/><br/>Але Мышанка вырвалася з ягоных абдымкаў і пачала ўцякаць куды вочы глядзяць. Пераслед рыцара скончыўся тым, што дзяўчына патрапіла ў раку і патанула ў цёмнай вадзе.
<br/><br/>Дзяўчына ператварылася ў русалку, а мясцовы люд, даведаўшыся пра здарэнне, назваў у гонар яе раку. Рыцар, усвядоміўшы свой грэх, вырашыў загладзіць яго і заснаваў у тым месцы паселішча, якое назваў Мыш.
<br/><br/>Русалка звычайна з'яўляецца ноччу ў святле месяца. Я некалькі начэй прасядзеў на розных берагах ракі каля Новай Мышы, але так і не сустрэў Мышанку. Я ўжо планаваў з'яжджаць, але Стэфан параіў наведаць мне мясцовую знахарку Сцепаніду.
<br/><br/>Аказалася, русалка паказваецца не ўсім. Сцепаніда сустракаецца з ёй кожны год, бо ў тым месцы, дзе Мышанка выходзіць на бераг, тут жа растуць і расквітаюць чарадзейныя мінушкі, якія выдатна выганяюць сурокі.
<br/><br/>Я не змог пабачыць Мышанку на ўласныя вочы, але ў мяне застаўся падарунак ад Сцепаніды — бутэлечка з чарадзейным зеллем…”
<br/><br/>З натакаў нячысціказнаўца Яна К. Лашкевіча`,
    source: "?",
  },
];

const windField = [
  {
    name: "Бялун",
    src: "Bialun",
    description:
      "Бялун - у беларускім фальклоры добры дух, а магчыма і паўбог, у выглядзе старога з доўгай барадой і вялізным носам. Бялун дапамагае заблудным выйсці з лесу, моўчкі паказвае ім неабходную сцежку. Часцей Бялун можа з'яўляцца перад бедняком у жытнім поле з торбай, якая вісіць на носе і просьбай выцерці нос, высмаркацца. Калі бядняк выканае просьбу, то з торбы пасыпяцца манеты, а Бялун знікне. Калі бядняк выцер нос рукой, то грошай выпадзе столькі, колькі змесціцца ў далонь. Калі ўтрэ нос шапкай або хусткай, то столькі, колькі змесціцца ў шапку або хустку.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018;",
  },
  {
    name: "Кашч",
    src: "Kashch",
    description: `Кашч або Кашчэй Бессмяротны – агульнаславянскі вобраз, які сустракаецца і ў беларускім фальклоры. Цяжка сказаць хто ён і адкуль з’явіўся, але дакладна вядома, што ён вельмі магутны і злы чараўнік. Стагоддзямі ён быў закаваны ў ланцугі, але хітрасцю быў вызвалены, у яго ёсць чароўны конь і цёмны вялікі палац, у якім жыве толькі ён адзін.<br/><br/>
Калісьці Кашч аддзяліў сваю душу ад цела і закаваў яе ў звычайную іголку, тым самым зрабіўшы сябе бессмяротным, але яго можна забіць, калі зламаць гэтую іголку. Асноўны занятак чарадзея - гэта выкрадаць маладых прыгожых дзяўчын.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Клікун",
    src: "Klikun",
    description: `Клікун - у беларускім фальклоры дарожны дух, не злы, але з выгляду вельмі грозны. Беларусы прадстаўлялі яго растрапаным, непрычасаным, у абарваным балахоне з доўгімі валасамі і рогам у руках, граючы на якім ён выдаваў візг, свіст і шум. Клікун раз'язджае па дарогах днём у спякоту і ноччу - пры святле месяца, на вялізным падуладным яму духу ў выглядзе змея.<br/><br/>
Клікун, як і ўсякі дух, не бачны. Днём яго можна пазнаць па пыле, які ўецца віхурай там, дзе ён праязджае. Уначы аб праездзе Клікуна можна пазнаць па паху серы, які выдае яго змей.<br/><br/>
Да Клікуна часта звяртаюцца з просьбай, адпраўляючыся ў дарогу, каб ён накіраваў падарожніка сваім клікам і свістам.`,
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Чароўны Свет, У.Васілевіч, В.Славук 2012; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Падвей",
    src: "Padvej",
    description:
      "Падвей - у беларускім фальклоры злосны дух моцнага ветру, віхор. Наляцеўшы на чалавека, ён можа згарнуць яму галаву, вывіхнуць руку ці нагу, адабраць прамову ці наслаць аднайменную хваробу. Падвей на чалавека можа напасці сам ці яго можа наслаць магутны вядзьмар. На Палессі лічылася, што жанчына, якую ў віхор закруціў Падвей можа зацяжарыць. У выніку, як правіла, можа нарадзіцца альбо чалавек-асілак, альбо нячысцік.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014; Bestiariusz Slowianski, W.Vargas, P.Zych 2021; Пережитки древнего миросозерцания у белорусов, А.Богдановчи 1895;",
  },
  {
    name: "Палевік",
    src: "Palevik",
    description:
      "Палевік - беларускі міфалагічны персанаж, які жыве ў палях і спрыяе росту і выспяванню збажыны. Лічылася, што Палевік жыве ў палях на ўзгорках, у ярах, сярод камянёў, з'яўляецца па краях поля, у межавых слупах і ямах, каля дрэў і хмызнякоў пасярод поля, часам яго можна пабачыць у лесе. Звычайна Палевік мае чорны, як зямля, твар і доўгую бараду з валасамі колеру спелых каласоў.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Палудніца",
    src: "Paludzenica",
    description:
      "Палудніцы, Палудзенiцы, Ржанiцы - у славянскай міфалогіі злыя палявыя духі, у прыватнасці ўвасабленне сонечнага ўдару. Яны гналі тых, хто працуе ў апоўдні (што ў народнай традыцыі забаронена), маглі выкрасці пакінутага ў полі дзіцяці. Таму Палудніцамi яшчэ палохалі дзяцей, каб тыя не лазілі па чужых палях і агародах.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Bestiariusz Slowianski, W.Vargas, P.Zych 2021;",
  },
  {
    name: "Шатан",
    src: "Shatan-i-Vihor",
    description: `Шатан або Шат і віхор. Віхор - стоўп паветра, што круціцца вакол уласнай осі і, калі рухаецца па вадзе або зямлі, захоплівае разам з сабою розныя рэчы, чым прыносіць разбурэнне і знішчэнне.<br/><br/>
Паводле беларускай міфалогіі найбольшы віхор робіць адзін з самых магутных і злобных чарцей - Шатан. Кожны каго захопіць такі віхор, павінень хутчэй садзіцца ці нават лажыцца на зямлю і маліцца, каб атагнаць чорта. Калі кінуць у віхор нож, то можна трапіць у чорта, пасля гэтага віхор спыніцца і з’явіцца Шатан, які пачне прасіць дастаць нож, калі гэта зрабіць, то нячысцік будзе служыць чалавеку да самай яго смерці.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Беларускі фальклор, А.Сержпутоўскі, П.Шэйн, Е.Раманаў, К.Каганец 2022;",
  },
  {
    name: "З рога ўсяго многа",
    src: "Z-Roga-Usiago-Mnoga",
    description:
      'Напэўна, кожны беларус ведае з дзяцінства казку "З рога ўсяго многа". А калі і не ведае, то ўсё роўна ведае, проста забыўся. Насамрэч існуе некалькі варыянтаў гэтага аповеда, але ва ўсіх з іх ёсць гэты артэфакт - Срэбраны Рог. Як правіла, артэфакт герою дастаецца ад істоты злучанай з паветрам - птушкі (журавель, бусел) ці самога духу ветра, верагодна Падвея. Асноўнае прызначэнне Срэбранага Рога гэта караць прагных і ненаедных людзей, тым якім усяго заўсёды мала. Як толькі чалавек, трымаючы рог, вымавіць: "З рога ўсяго многа!", то тут жа з рога з\'явяцца ад 3 да 12 духаў або зданяў з бізунамі і пачнуць біць імі таго, хто іх абудзіў. Каб яны зніклі назад у рог, трэба сказаць: "Ох, усё ў рог!".',
    source: "З рога ўсяго многа",
  },
  {
    name: "Жыж",
    src: "Zhyzh",
    description:
      "Жыж, Жыжаль, Жыжак – у беларускай міфалогіі адзін са старых паўбагоў, дух ляснога пажару, дух агню. Жыж жыве глыбока пад зямлёй і сваім агнём грэе зямлю. Часам Жыж можа выкідваць больш агню, чым звычайна, з-за чаго на паверхні пачынаюцца лясныя і палявыя пажары. Жыж адзін з ворагаў Пяруна.",
    source:
      "Белорусские народные предания, П.Древлянский 1846-1852 / Рукописи, которых не было, Е.Левкиевская 2002; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Героі міфаў і легенд Беларусі, Г.Коршунаў 2018; Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Лён і Лляніца",
    src: "Lion-i-lljanitsa",
    description: `"Трапіў я неяк на Веткаўшчыну ў час пасеву лёну. У гэтых мясцінах існуе дужа цікавы звычай клікаць палявых духаў пад час пасеву. У льнянога поля свае асабістыя духі - дзядуля Лён і яго жонка Лляніца. Мне пашчасціла пабачыць іх на свае вочы, знешне яны вельмі адрозніваюцца ад Палевіка."' +
        <br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Галышка",
    src: "Galyshka",
    description: `"Неяк я ішоў праз невялікую вёску каля Глыбокага і раптоўна збоку пачуў: «Галыш! Куды пабег галыш?!». Мяне перасмыкнула і душа сышла ў пяткі. Аказалася, гэта мясцовы стары сюсюкаў са сваім паўгадавым унукам, той басанож бегаў па траве.
<br/><br/>Так, у гэтых месцах ужо і забыліся, што раней падобным словам звалі жудасных Русалак. Я ніколі не забуду сустрэчу з адной з іх.
<br/><br/>Я тады яшчэ быў маладзейшы і па даручэнні Шэйна, быўшы яго карэспандэнтам, адправіўся ў мястэчка недалёка ад Крычава, дзе стаялі старажытныя валуны.
<br/><br/>У вёсцы я сустрэў сям'ю, дзіця якой мела страшныя раны на твары. Маці і бацька расказалі мне пра тое, што здарылася з ім і гэта адцягнула маю ўвагу ад старажытных валуноў.
<br/><br/>Аказалася, малец быў не адзіны, хто пацярпеў ад пачвары. Разам з яго бацькам мы адправіліся ў суседняе поле вылавіць і забіць монстра.
<br/><br/>Галышка - страшная русалка з лысай галавой, белай скурай і чырвонымі вачыма. Яе грудзі пакрытыя іголкамі, яна ловіць дзяцей і падлеткаў у палях, прымушае піць малако з калючых грудзей. Верагодна, Галышка гэта продак Цыцохі і Цыгры. Калісьці даўно гэтыя русалкі мігравалі на Палессе і з'явіліся такія жабападобныя разнавіднасці, якія па сваіх паводзінах вельмі падобныя.
<br/><br/>Апошніх зараз шмат, а вось Галышкі амаль зніклі. Мы з бацькам таго хлопчыка тады ледзьве жывыя засталіся. Калі б не мой сталёвы крыжык… Шэйну я пра гэтыя прыгоды вядома ж нічога не распавёў. Ды ён і не паверыў бы…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "Беларускі фальклор. Выпуск 3, 2016; Звесткі ад падпісантаў",
  },
  {
    name: "Гарцукі",
    src: "Hartsuki",
    description: `"Чорныя кудлатыя крылатыя і вельмі зласлівыя істоты. Некаторыя фалькларысты ў сваіх запісах адзначаюць, што гэта духі, якія служаць і падпарадкоўваюцца паганскаму богу Пяруну.
<br/><br/>Мае назіранні і досвед кажуць аб тым, што гэта звычайныя нячысцікі. Верагодна, яны селяцца не ў нашых краях, я ні воднага разу не знаходзіў іх логава ні на балотах, ні ў палях, ні ў лесе каля возера. Хутчэй за ўсё яны жывуць і множацца недзе ў горных раёнах суседніх краін. Не ведаю якую назву ім далі там, але тут у нас мясцовыя іх клічуць Гарцукі.
<br/><br/>Часцей за ўсё гэтыя дэманічныя птушкі налятаюць суправаджаючы дрэннае надвор'е. Адсюль людзі і думаюць, што яны самі выклікаюць непагадзь і ёсць сувязь з Грымотнікам.
<br/><br/>Яны вельмі хутка перасоўваюцца па зямлі і могуць стралой лётаць па паветры. Іх улюблёная справа гэта даймаць коней і іншую буйную рагатую жывёлу."
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
  },
  {
    name: "Дзюндзік",
    src: "Dziundzik",
    description: `У вёсках і палях ад Добруша да Кармоў можна сустрэць малых зласлівых нячысцікаў - Дзюндзікаў. 
<br/><br/>Дзюндзік не з’яўляецца смяротна небяспечнай істотай, але вельмі не любіць дзяцей і пры сустрэчы можа добра збіць малога сваёй даўбёжкай. Звычайна, нячысцік выглядае, як клубок бруднай збітай травы з доўгімі нагамі, падобнымі на бусліныя. І заўсёды ў руках жалезная даўбёжка - нешта накшталт малатка, часам нячысцік вырабляе яго сабе сам, часам любіць зкрасці прыладу ў чалавека.
<br/><br/>Тыя што жывуць у жыце больш жоўтыя, тыя што сустракаюцца на агародах больш зялёныя…
        <br/><br/>“Дзюндзік па гароду скача: галава клубочкам, цельца лубочкам, драцяныя ножкі, салам’яны рожкі, даўбёжка…”`,
    source:
      "Традыцыйная мастацкая култура беларусаў, Т.Б.Варфаламеева 2012, Часопіс 'Живая старина' 2014; Звесткі ад падпісантаў",
  },
  {
    name: "Зіма",
    src: "Zima",
    description: `Зіма - паводле беларускага фальклора гэта персаніфікаваная пара года, жаночая пара духа Мароза, Зюзі.<br/><br/>
Зіма ўяўлялася ў выглядзе прыгожай старой з сівымі валасамі ў ледзяной сукенцы. Заўжды яна запрашала Мароза ў госці, але часам ён мог пакрыўдзіцца на нешта і не ісці да яе. Тады Зіма пачынала плакаць горкімі халоднымі слязамі. Адсюль і зімы без мароза і снега, адныя хлюп ды бруд.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Баровы",
    src: "Baravy",
    description: `Прысыпуш - паводле беларускага фальклору гэта дзіцяці, якое задавіла ў часе сну маці, "прыспала". Беларусы лічылі, што прысыпуш гэта падменыш - дзіцяці нячыстай сілы, падкінуты замест чалавечага, якога чэрці ўзялі да сабе.
<br/><br/>Чэрці выраблялі прысыпуша з дрэва, з калоды, пакінутай сярод пасечных дроў. Таму заўсёды раяць дасякаць калодкі.
<br/><br/>На Палессі і ўвогуле ва ўсходняй частцы Беларусі верылі, што з прысыпуша потым з’яўляецца лясны дух - Баравік або Баровы, нешта накшталт Лясуна або Дабрахожага.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Бурая Баба",
    src: "Biraja-Baba",
    description: `Бурая Баба - істота ў якую была вера ў жыхароў беларускага Палесся. Брыдкая старая, лысая, без носа і вушэй, з вялізным капялюшам у выглядзе мухамора.<br/><br/>
Палешукі казалі, што Бурая Баба гэта жонка Вупыра, пры гэтым мясцовыя жыхары лічылі пачвару недарэчнай і нават смешнай.<br/><br/>
Кажуць, што Бурая Баба часта з'яўляецца з нагой у руцэ, якой яна любіць пачухаць сваю лысіну. Хтосьці кажа, што гэта яе ўласная нага, але мне здаецца, гэта нага таго самага палешука, які думаў, што гэты монстар смешны і меў неасцярожнасць занадта блізка да яго падысці.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Святы Калодзеж",
    src: "Kalodzezh",
    description:
      "Святы Калодзеж. Паводле старажытнай беларускай легенды, у вёсцы Маргавіцы, што каля Бягомля, жыла маладая ўдавіца. Была яна яшчэ той распусніцай, але захацелася ёй стаць найпершай прыгажуняй у наваколлі. Знахары казалі, што для гэтага трэба ў ноч на балоты пайсці і павадзіць з чарцямі карагоды, а на світанні вярнуцца дахаты. Так удавіца і зрабіла, але чэрці яе дадому не адпусцілі, а наадварот зацягнулі ў дрыгву. Ад пакут, дух дзяўчыны пачаў ліць слезы, ды так, што на тым месцы прабілася крыніца. Слёзы грэшніцы, якая шчыра пакаялася - гэта святая вадзіца. Людзі зрабілі там калодзеж. Рэшткі яго і дагэтуль на тым месцы ёсць.",
    source: "Сіняя світа, У.Сіўчыкаў 2015;",
  },
  {
    name: "Пякельнікі",
    src: "Piakelniki",
    description: `Пякельнікі - у беларускім фальклоры гэта такая разнавіднасць чарцей, якія заўсёды знаходзяцца ў Пекле і нікуды адтуль не выходзяць. Першапачаткова Пякельнікі гэта звычайныя чэрці, якія снуюць на балотах у чалавечым свеце, але з-за сваёй нездатнасці (дурасці, слабасці, старасці) іх ссылаюць на заўсёды ў Пекла.<br/><br/>
З цягам часу іх аблічча прыстасуецца да асяроддзя пад зямлёй: поўсць ападае і скура становіцца тонкая і чорная, як у кажана, замест капытоў вырастаюць хваткія кіпцюры. У змроку Пекла жудасна вылучаюцца іх белыя вочы, зубы і доўгія кіпцюры.<br/><br/>
Пякельнікі паліваюць грэшныя душы смалой і серай, якая льецца ў іх з рота і ноздраў. Але пякельная праца ім не падабаецца: каб яны наперад ведалі, што трапяць у Пекла, то прыклалі б усе намаганні, каб таго пазбегнуць.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
  {
    name: "Прожар і Сцень",
    src: "Prozhar-scen",
    description: `Прожар і Сцень. Прожар ці Голад, Хаўпілка - у беларускім фальклоры злы дух, які ўсяляецца ў чалавека, часта менавіта ў дзяцей, і робіць іх ненаеднымі, пражэрлівымі, галоднымі. Само ж дзіця, нягледзячы на воўчы апетыт, імкліва худнее.<br/><br/>
Лічылася, што дэман усяляўся ў дзіця, чыя маці не выконвала пэўныя правілы падчас цяжарнасці. Напрыклад, нельга было ўжываць у ежу мяса жывёлы, якую параніў драпежнік, таксама нельга было перадаваць ежу праз дзіцячы ложачак.<br/><br/>
Сцень - у беларускім фальклоры дэман, які ўсяляўся ў чалавека і забіраў яго цень, пры гэтым чалавек страшна худнеў, сох, спыняў есці, усяго вакол баяўся, асабліва свайго цела, вар’яцеў.`,
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011;",
  },
  {
    name: "Злыдні і Злыдня",
    src: "Zlydni",
    description:
      "Злыдні - у беларускім фальклоры ні то гарбатыя каты, ні то сабакі, каўматыя лахматыя, ходзяць зграяй, часценька жывуць у хаце таго, хто хоча хутка разбагацець, прыносяць яму адныя беды. Злыдня, Нядоля - жаночы дух, без вачэй, вушэй ды языка ў якога магічным чынам ператварылі балотную змяю, яна таксама нясе адныя беды, чартаўшчыну ды мярзоты. Адвязацца ад гэтых нячысцікаў можна толькі падманам.",
    source:
      "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012; Язычніцтва старажытных беларусаў, Л.Дучыц, І.Клімковіч 2014;",
  },
  {
    name: "Лесавіца",
    src: "Lesavica",
    description: `Лесавіца - у беларускім фальклоры гэта разнавіднасць лясных Русалак, якія выглядаюць як звычайныя жанчыны з доўгімі распушчанымі валасамі, вочы ў іх чорнага колеру, зубы маленькія і вострыя, замест ног капыты, а скура зялёная, як лісце дрэў.<br/><br/>
Лічыцца, што Лесавіцы гэта душы загубленых Лясуном дзяўчат, якія былі праклятыя бацькамі і згубіліся ў лесе. Як і ўсе Русалкі, Лесавіцы любяць гойдацца на галінах дрэў на Русальным тыдні і завабліваць юнакоў у гушчар лесу.`,
    source: "Беларускі фальклор. Выпуск 3, 2016;",
  },
  {
    name: "Карузлік",
    src: "Karuzlik",
    description: `"Карузлік - у беларускай міфалогіі назва гнома, карліка. Гэты маленькі народзец часты персанаж у фальклоры народаў Еўропы. Выглядаюць яны, як звычайныя людзі, але меншых памераў і, як правіла, заўсёды з густой барадой, якая сведчыць аб іх шаноўным ўзросце.
<br/><br/>У розных народаў гэтыя істоты маюць розныя назвы, ды і паводзяць сябе па-рознаму. У германцаў гэта Цвергі, Гномы ці Нібелунгі, у скандынаваў Ніссэ, у шатландцаў гэта Брауні, у егіпцян Бесы, у французаў Наіны.
<br/><br/>Карузлікі дакладна з'яўляюцца сваякамі Красналюдаў, але ў адрозненні ад іх, яны не заўсёды станоўчы персанаж. Красналюдкі істоты добрыя, яны памагатыя чалавека. Карузлікі ж могуць быць як злымі, так і добрымі. Як казачны персанаж яны часцей злыя. Напрыклад, яны могуць заблытаць чалавека ў лесе, каб той заблукаў і згінуў там. Пашанцуе, калі побач апынецца Алень Губерта, але такі поспех прыходзіць не да ўсіх.
<br/><br/>Карузлікі бліжэй да сядзібных духаў, якія таксама заўсёды невялікага росту і могуць паводзіць сябе неадназначна: быць як добрымі, так і злымі. Асабліва да іх блізкія такія ж неадназначныя істоты, як напрыклад Хохлік.
<br/><br/>Карузлікі істоты старажытныя, яны як і Волаты з'явіліся на зямлі яшчэ да чалавека. Таксама яны валодаюць чараўніцтвам. Злыя ведзьмакі могуць заклікаць да сябе на службу злых Карузлікаў, добрым жа знахарам часам дапамагаюць добрыя Карузлікі. Мусіць, адзін з самых вядомых Карузлікаў беларускага фальклору гэта стары Ох."
<br/><br/>З нататкаў нячысціказнаўца Я.К.Лашкевіча`,
    source: "",
  },
  {
    name: "Гагыль",
    src: "Hagyl",
    description: `“Вядома ж я не паверыў Анжэю. Ён хоць і дасведчаны нячысціказнавец, але любіць трохі перабольшваць. Гісторыя пра тое, што ён пазбавіўся ад ведзьмы, завёўшы тую ў гушчар лесу ў лапы самаго Гагыля, гучала непраўдападобна.
<br/><br/>Гагыль — лясны дэман і вельмі небяспечны дух для людзей, якія займаюцца вядзьмарствам.
<br/><br/>Ведзьмакоў і вядзьмарак ён пры сустрэчы можа нават і забіць. А вось людзям чыстым сваёй душою, ён наадварот дапаможа выбрацца з лесу.
<br/><br/>Знешнасць нячысціка жудасная: гарбаты, перасоўваецца ён на чатырох доўгіх нагах, цела выглядае як зблытаныя паміж сабой галінкі дрэў, лісце, лаза і анучы. Пашча ў яго велізарная — у раз чалавека можа праглынуць.
<br/><br/>Чарнакніжнікі сапраўды вельмі баяцца Гагылёў. А для знахаркі сустрэча з гэтым духам сапраўднае выпрабаванне, бо Гагыль у імгненне распазнае, ці не прывядуць тайныя веды лекарку да цёмнага шляху —шляху вядзьмаркі. Калі так, то ёй будзе дрэнна, у іншым выпадку, Гагыль сам дапаможа адшукаць гаючую траву.
<br/><br/>Але духі гэтыя вельмі рэдкія, Хведар казаў, што толькі ў Магілёўскіх ускраінах і засталіся, больш нідзе.“
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
  {
    name: "Дра-птах",
    src: "Dra-ptah",
    description: `“Дра-птах — вельмі рэдкая чароўная птушка, здаецца яна нібы пылае агнём, а пёр’е ззяе золатам. Аднойчы, я бачыў такую адну, яна з'явілася раптам асвятліўшы ўсё балота сваім яркім святлом, а пасля выдрапала вочы той жудаснай змяінай пачвары. Пёры на хвасце гэтай птушкі былі падобны на вогненныя лісты папараці. Адно такое пяро здольна свяціць ярчэй ліхтара на цёмнай лясной сцяжынцы. Але з часам пяро цвярдзее і пераўтвараецца ў звычайнае золата.
<br/><br/>Вясковыя бабкі любяць расказваць пра Дра-птаха казкі і завуць яе Жар-птушка. Нячысціказнаўцы замежжа называюць яе Фенікс.”
<br/><br/>З нататкаў нячысціказнаўца Хведара Шэрага`,
    source: "",
  },
  {
    name: "Геб",
    src: "Heb",
    description: `"Зноў ён тут! Нават з далёк я бачыў, як у яго чырвонай бурбалцы хтосьці безнадзейна боўтаецца і спрабуе выбрацца. Геб — жудасная істота, якая мае змяіную прыроду. Я павінен дапамагчы небараку, але на гэты раз у мяне няма стрэльбы…"
<br/><br/>З нататкаў нячысціказнаўца Яна Клікста Лашкевіча`,
    source: "",
  },
  {
    name: "Воспа",
    src: "Vospa",
    description:
        "Воспа, Цётка Воспіца, Ліхая, Рабуха, Шчарбачка – у беларускай міфалогіі злосная агідная старая з адным моцным зубам, які тырчыць вонкі. Яна пырскае сваёю атрутнаю слінаю ў выбраную ахвяру, з-за чаго на твары ў той застаюцца сляды аднаіменнай хваробы. Воспа падарожнічае па свеце і разносіць хваробу, але яна аберагае тыя хаты, дзе ёй далі прытулак.",
    source:
        "Міфалогія беларусаў: Энцыклапедычны слоўнік, Т.Валодзіна, С.Санько 2011; Міфы бацькаўшчыны, У.Васілевіч 1994; Чароўны Свет, У.Васілевіч, В.Славук 2012;",
  },
];

const monsters = [
  {
    name: "Лясныя",
    path: "forest",
    root: "monsters",
    items: [...forest],
  },
  {
    name: "Водныя",
    path: "water",
    root: "monsters",
    items: [...water],
  },
  {
    name: "Балотныя",
    path: "swamp",
    root: "monsters",
    items: [...swamp],
  },
  {
    name: "Дварова-хатнія",
    path: "home",
    root: "monsters",
    items: [...home],
  },
  {
    name: "Паветраныя, Палявыя ды іншыя",
    path: "windField",
    root: "monsters",
    items: [...windField],
  },
];

export default function loadMonsters() {
  return {
    data: {
      categories: monsters,
      title: "БЕЛАРУСКІЯ НЯЧЫСЦІКІ",
      description: `
        Творчы праект «Беларускія Нячысцікі» быў распачаты ў 2021 годзе, мэта гэтага праекта — намаляваць шэраг ілюстрацый у стылі аўтэнтычнага фэнтэзі, якія будуць прысвечаны містычным ды міфалагічным істотам беларускага фальклору, кабінетнай міфалогіі, а таксама персанажам мясцовых легенд і міфаў.<br/><br/>
        На дадзены момант у калекцыі больш за 200 ілюстрацый, сярод якіх папулярныя беларускія фальклорныя персанажы: Хатнік, Ёўнік, Пуннік, Лясун, Вадзянік, Балотнік, Русалка і г.д.; Кумяльган і Вазіла - персанажы апісаныя Паўлам Шпілеўскім, якія лічацца кабінетнай міфалогіяй і крытыкуюцца навуковай супольнасцю, але ўжо сталі культавымі. А таксама вельмі рэдкія персанажы, накшталт Паднора - мышынага караля, або дэманіцы Вуймы ці Ўлішыцы, якую апісваў спадар Івана Пятровіча Барычэўскага.<br/><br/>
        У 2022 годзе выйшаў першы папяровы артбук з серыі, прысвечаны лясным нячысцікам і духам "Беларускія Нячысцікі: Лясныя".<br/>
        У 2024 годзе выйшла другая частка прысвечаны водным і балотным нячысцікам ды духам "Беларускія Нячысцікі: Водныя і балотныя".<br/><br/>
        Галоўны герой кнігі спадар Ян Клікст Лашкевіч - нячысціказнаўца, паляўнічы на прыгоды і даследчык усяго містычнага. Ён вандруе па Беларусі і збірае звесткі пра розных беларускіх нячысцікаў. Гэта такі збіральны вобраз фалкларыстаў XIX стагоддзя.
        `,
    },
  };
}
